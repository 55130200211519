import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger, ButtonGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';

import { showNotification, hideNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import AddIcon from '../../../../../assets/images/module/general/AddIcon.svg';
import Logo from '../../../../../assets/images/module/general/Logo.svg';
import Messenger from '../../../../../assets/images/module/general/Messenger.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check, ThreeDRotationSharp } from "@material-ui/icons";
import { Alert, Group, Loader, Select, Skeleton, Stack, Text } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dayjs from 'dayjs';
import CopyIcon from '../../../SVGs/copyIcon';
import PreviewMessagePdf from '../PreviewMessage/PreviewMessagePdf';
import PreviewMessageEmail from '../PreviewMessage/PreviewMessageEmail';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import MessagesIcon from '../../../../../assets/images/module/general/MessagesIcon.svg';
import PreviewMessagePdfBillingStatement from '../PreviewMessage/PreviewMessagePdfBillingStatement';
import PreviewMessagePdfMeterReadingsAndConsumptions from '../PreviewMessage/PreviewMessagePdfMeterReadingsAndConsumptions';
import PreviewMessagePdfHeatingInformation from '../PreviewMessage/PreviewMessagePdfHeatingInformation';
import PdfViewer from '../PdfViewer/PdfViewer';
import Pdf, { usePdf } from '@mikecousins/react-pdf';
import { ZoomIn, ZoomOut} from '@material-ui/icons';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import PreviewMessageTenantPortal from '../PreviewMessage/PreviewMessageTenantPortal';
import UtilityBillingTemplate2 from '../../../../../views/pages/Module/UtilityBilling/UtilityBillingTemplate2';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment } = Autoload.MainHelper;
dayjs.extend(customParseFormat)

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

const FilePreview = React.memo(({file}) => {
    const [imgScale, setImgScale] = React.useState(100);
    if (file) {
    //   let re = /(?:\.([^.]+))?$/;
    //   var extension = re.exec(!edit ? file.name : typeof(file) === 'object' ? file.name : file)[1];
    //   extension = extension && extension.split('?')[0];
    
    //   if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
    //     const url = !edit ? URL.createObjectURL(file) : typeof(file) === 'object' ? URL.createObjectURL(file) : file;
    //     return (
    //     <div style={{width: `${imgScale}%`, textAlign:'center'}}>
    //       <img src={url} className="main-img" alt="no" />
    //       <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
    //       top: isMobile ? 367 : 667, left: 4}}> 
    //         <nav>
    //             <Button size={'sm'} disabled={imgScale === 200} onClick={() => setImgScale(imgScale + 10)} children={<ZoomIn/>}/>
    //             {' '}
    //             <Button size={'sm'} disabled={imgScale === 100} onClick={() => setImgScale(imgScale - 10)} children={<ZoomOut/>}/>
    //             {' '}
    //             {/* <Button size={'sm'} onClick={() => onExpand()} children={<img src={ExpandIcon}/>}/> */}
    //         </nav>
    //       </div>
    //     </div>
    //     );
    //   } else 
      if (typeof(file) === 'object') {
        const url =URL.createObjectURL(file);
        return (
          <PdfViewer file={url} preview={true}/>
        );
      }
    }
  }); 

const MyPdfViewer = ({file, isMobile}) => {
  const canvasRef = useRef(null);
  const [imgScale, setImgScale] = React.useState(isMobile ? 0.5 : 1.33);
  const [pdfListArray, setPdfListArray] = useState([]);

  const { pdfDocument } = usePdf({
    file,
    canvasRef
  });

  console.log('MyPdfViewer: ', pdfDocument, file);

  const pdfPages=()=>{
    let pdfArray=[];
    for (let index = 1; index <= pdfDocument.numPages; index++) {
      pdfArray.push(<Pdf file={file} scale={imgScale} page={index}/>);
    }
    console.log('pdfPages: ', pdfArray);
    return pdfArray;
  }
  useEffect(() => {
    setTimeout(() => {
      console.log('useEffect: ', pdfDocument);
      pdfDocument && setPdfListArray(pdfPages())
    }, 2000);
  }, [pdfDocument]);
  return (
    <Stack>
      {pdfDocument && pdfPages()}
      {isMobile &&
      <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
        top: 505, left: 40}}> 
        <nav>
          <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 3} onClick={() => setImgScale(imgScale + 0.1)} children={<ZoomIn/>}/>
          {' '}
          <Button style={{backgroundColor: '#f8f8fa', border: '1px solid #d5dce6', color: '#252563'}} size={'sm'} disabled={imgScale === 0.5} onClick={() => setImgScale(imgScale - 0.1)} children={<ZoomOut/>}/>
          {' '}
        </nav>
      </div>}
    </Stack>
  );
};
class MessageTemplatesModal extends Component {
  state = {
    selectedRecipient: null,
    selectedView: 'postal',
    allBillingStatements: [],
    selectedBillingStatement: null,
    selectedCostAllocations: [],
    loader: false,
    meterReadingsSwitch: false,
    compositionOfHeatingCostsSwitch: false,
    costSplitWarmWaterAndHeatingSwitch: false,
    splitBaseConsumptionCostsSwitch: false,
    vatSwitch: false,
    laborCostSwitch: false,
    energyConsumptionCO2EmissionsSwitch: false,
    compareHeatingConsumptionBarChartSwitch: false,
    energyLevelsInfoSwitch: false,
    arbitraryBoardInfoSwitch: false, 
    energyAdviceCenterInfoSwitch: false,
    co2TaxInfoSwitch: false,
    ubData: null,
    messagePreviewPdf: null,
    ubPdfUrl: null,
    rentalPayment: null,
    rents: [],
    tenantRemainingRentObject: null,
    firstLoader: false,
    loaderRecipientId: null,
    isPreTaxInfoHidden: false
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    // this.getRents('c67f6841-8606-4a80-0acc-08dc739f8102');
    this.props.allRecipients.length > 0 && this.setState({selectedRecipient: this.props.allRecipients[0].id, firstLoader: true}, ()=> this.props.messageData.utilityBillingId ? this.getBillingStatements() : this.props.messageData.templateType === 'RentalPayment' ? this.getRentalPayment(this.props.allRecipients[0].rentalPaymentId) : this.props.messageData.templateType === 'Tenant' ? this.getRents(this.props.allRecipients[0].tenantId) : setTimeout(() => {
      this.downloadPdf();
    }, 600));
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.messageData.utilityBillingId);
    if(data !== undefined){
      this.setState({
        meterReadingsSwitch: !data.isMeterReadingAndConsumptionInfoHidden,
        compositionOfHeatingCostsSwitch: !data.isHeatingCostInfoHidden,
        costSplitWarmWaterAndHeatingSwitch: !data.isCostSplitWarmWaterAndHeatingCostInfoHidden,
        splitBaseConsumptionCostsSwitch: !data.isSplitBaseConsumptionCostInfoHidden,
        vatSwitch: !data.isVATHidden,
        laborCostSwitch: !data.isLaborCostHidden,
        energyConsumptionCO2EmissionsSwitch: !data.isEnergyConsumptionCO2EmissionsInfoHidden,
        compareHeatingConsumptionBarChartSwitch: !data.isPreviousPeriodEnergyComparisonInfoHidden,
        energyLevelsInfoSwitch: !data.isEnergyLevelsInfoHidden,
        arbitraryBoardInfoSwitch: !data.isArbitraryBoardInfoHidden, 
        energyAdviceCenterInfoSwitch: !data.isEnergyAdviceCenterInfoHidden,
        co2TaxInfoSwitch: !data.isCO2TaxInfoHidden,
        isPreTaxInfoHidden: data.isPreTaxInfoHidden,
        ubData: data
      })
    }
    //this.downloadPdf();
  }

  getRentalPayment=(id)=>{
    this.setState({loader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payment',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payment(response){
    if(response.status && response.statuscode === 200){
      this.setState({rentalPayment: response.result},()=> {
        this.getAllTotal(response.result.tenantId);
      });
    }else{
      this.setState({loader: false, loaderRecipientId: null});
    }
  }

  getAllTotal=(tenantId)=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/GetAllTotal?propertyId='+ propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payment_get_all_total',
      {tenantId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payment_get_all_total(response){
    if(response.status && response.statuscode === 200){
      console.log('handle_get_rental_payment_get_all_total:', response);
      let tenantRemainingRentObject= response.result.find((remainingRent)=> remainingRent.tenantId === response.other.tenantId)
      this.setState({tenantRemainingRentObject: tenantRemainingRentObject !== undefined ? (tenantRemainingRentObject.expectedNetRent + tenantRemainingRentObject.expectedAdvancePayment) - (tenantRemainingRentObject.receivedNetRent + tenantRemainingRentObject.receivedAdvancePayment) : null}, ()=> this.getRents(response.other.tenantId));
    }
  }

  getRents = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rents?offset=0&fetch=100&tenantId=' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rents',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_rents(response){
    if(response.status && response.statuscode === 200){
      this.setState({rents: response.result}, ()=> setTimeout(() => {
        this.downloadPdf();
      }, 600));
    }else{
      this.setState({loader: false, loaderRecipientId: null});
    }
  }

  getBillingStatements=()=>{
    this.setState({
      loader: this.props.allRecipients.find((recipient)=> recipient.id === this.state.selectedRecipient)?.isPostEnabled ? true : false,
      firstLoader: this.props.allRecipients.find((recipient)=> recipient.id === this.state.selectedRecipient)?.isPostEnabled ? true : false
    }, ()=> {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId='+this.props.currentDataId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  }

  handleGetBillingStatements(response){
    if(response.status && response.statuscode === 200){
        let recipient = this.props.allRecipients.find((recipient)=> recipient.id === this.state.selectedRecipient);
        this.setState({allBillingStatements: response.result, selectedBillingStatement: recipient ? response.result.find((billingStatement)=> billingStatement.tenantId === recipient.tenantId) : null}, ()=> {
          //this.setState({loader: false}, ()=> 
          setTimeout(() => {
            if(!this.props.messageData.utilityBillingId){
              this.downloadPdf();
            }
          }, 2000)
          //);
        });
    }
  }

  getBillingStatementById=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements/'+id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBillingStatementById',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetBillingStatementById(response){
    if(response.status && response.statuscode === 200){
        this.setState({selectedBillingStatement: response.result});
    }
  }

  getCostAllocationsForBillingStatements=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costAllocations/forBillingStatement?utilityBillingId='+this.props.currentDataId+'&billingStatementId='+id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetCostAllocationsForBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetCostAllocationsForBillingStatements(response){
    if(response.status && response.statuscode === 200){
        this.setState({selectedCostAllocations: response.result});
    }
  }

  handleRecipientChange=(recipientId)=>{
    let recipient = this.props.allRecipients.find((recipient)=> recipient.id === recipientId);
    this.setState({selectedRecipient: recipientId, selectedBillingStatement: recipient !== undefined ? this.state.allBillingStatements.find((billingStatement)=> billingStatement.tenantId === recipient.tenantId) : null, messagePreviewPdf: null, ubPdfUrl: null}, ()=> {
      // let mainMessageDivsToPrint= document.getElementById('message-divs-to-print');
      // mainMessageDivsToPrint.style.display= 'block';
      this.setState({loader: this.props.allRecipients.find((recipient)=> recipient.id === this.state.selectedRecipient)?.isPostEnabled ? true : false, loaderRecipientId: this.state.selectedRecipient}, ()=> 
      setTimeout(() => {
        if(!this.props.messageData.utilityBillingId){
          if(this.props.messageData.templateType === 'Tenant'){
            this.getRents(recipient?.tenantId);
          }else{
            this.downloadPdf(); 
          }
        }else{
          if(this.state.selectedView !== 'postal'){
            this.setState({loader: false, loaderRecipientId: null});
          }
        }
      }, 2000));
    })
  }

  renderPostalEmailButtonGroup=()=>{
    const {selectedView, firstLoader, loader, selectedRecipient, messagePreviewPdf} = this.state;
    const {messageData, allRecipients} = this.props;
    console.log('renderPostalEmailButtonGroup: ', messageData);
    return(!firstLoader &&
      <ButtonGroup className='btn-group-messaging'>
        <Button
        className={`switch-btn ${selectedView === 'postal' && 'active'}`}
        style={{width: '110px', fontWeight: '400'}}
        onClick={() => this.setState({selectedView: 'postal', loader: messagePreviewPdf === null ? (allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled ? true : false) : false, loaderRecipientId: messagePreviewPdf === null ? (allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled ?  selectedRecipient : null) : null}, ()=> {
          if((this.props.messageData.templateType === 'Tenant' || this.props.messageData.templateType === 'General') && allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled){
            setTimeout(() => {
              this.downloadPdf();
            }, 600);
          }
        })}
        disabled={loader}
        //disabled={!allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginTop: '-1px'}}>
                Brief
                </span>
            </div>
        </Button>
        
        <Button
        className={`switch-btn ${selectedView === 'email' && 'active'}`}
        style={{width: '110px'}}
        onClick={() => this.setState({selectedView: 'email'})}
        disabled={loader}
        //disabled={!allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isEmailEnabled}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginTop: '-1px'}}>
                Email
                </span>
            </div>
        </Button>
        {messageData?.templateType !== 'General' && <Button
        className={`switch-btn ${selectedView === 'tenantPortal' && 'active'}`}
        style={{width: '110px'}}
        onClick={() => this.setState({selectedView: 'tenantPortal'})}
        disabled={loader}
        //disabled={!allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isEmailEnabled}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginTop: '-1px'}}>
                Mieterportal
                </span>
            </div>
        </Button>}
      </ButtonGroup>
    )
  }

  downloadPdf=()=>{
    const {messageData} = this.props;
    this.setState({loader: true});
    const data = new FormData();
    let html = document.getElementById(`messageDivToPrint`);
    console.log('html: ', html);
    let doc = document.implementation.createHTMLDocument("New Document");
    try {
      doc.body.appendChild(html);
      const blob = new Blob([doc.documentElement.innerHTML], {
        type: 'text/html'
      });
      
      const fileName = `messagingPreview-${this.props.propertySelected.name}-${new Date().valueOf()}}`;
      let file = new File([blob], fileName);
      file.originalname = fileName;
      console.log('downloadPdfFromServer: ', file);
      // var a = document.createElement("a");
      // document.body.appendChild(a);
      // let url = window.URL.createObjectURL(file);
      // a.href = url;
      // a.download = 'test.html';
      // a.click();
      // window.URL.revokeObjectURL(url);

      // this.downloadPdfFromServer(count+1);

      data.append('file', file);
      fetch(`https://${window.location.href.includes('app') ? 'app' : 'dev'}.hellohousing.de/api/reporting-api/pdf`,
        {
          method: 'POST',
          body: data,
          headers: {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        }).then((response)=> response.blob()
        .then(data => {
          
          //download progress
          var a = document.createElement("a");
          document.body.appendChild(a);
          console.log('data: ', data);
          let url = window.URL.createObjectURL(data);
          this.setState({messagePreviewPdf: url, loader: false, firstLoader: false, loaderRecipientId: null}, ()=> {
            // if(messageData.utilityBillingId === null){
            //   let mainMessageDivsToPrint= document.getElementById('message-divs-to-print');
            //   mainMessageDivsToPrint.style.display= 'none';
            // }
          });
          // a.href = url;
          // a.download = 'test.pdf';
          // a.click();
          //window.URL.revokeObjectURL(url);
          //downloadStatus.textContent=`PDF-Dokumente erstellen ${count > this.state.pages ? this.state.pages : count} / ${this.state.pages}`;
          
        }));
    } catch (e) {
      console.log(e);
      this.setState({loader: false, loaderRecipientId: null, firstLoader: false});
    }
  }

  renderMessagePreviewBox=()=>{
    const { allRecipients, messageData, senderAddress, senderEmail, billingPeriod, rentalUnits, propertySelected, accountData, isMobile, profile, currentSubscription } = this.props;
    const { selectedRecipient, selectedView, selectedBillingStatement, loader, meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, ubData, messagePreviewPdf, ubPdfUrl, rentalPayment, rents, tenantRemainingRentObject, firstLoader, vatSwitch, isPreTaxInfoHidden, laborCostSwitch, energyConsumptionCO2EmissionsSwitch, compareHeatingConsumptionBarChartSwitch, energyLevelsInfoSwitch, arbitraryBoardInfoSwitch, energyAdviceCenterInfoSwitch, co2TaxInfoSwitch } = this.state;
    return(
      <Skeleton visible={firstLoader}>
        <div 
        className='message-preview-box'
        style={{
        background: 'grey', 
        // minWidth: '700px',//'285px', 
        // maxWidth: '700px',//'285px', 
        minHeight: isMobile ? '400px' : '700px',//'431px', 
        maxHeight: isMobile ? '400px' : '700px',//'431px', 
        padding: '10px',
        //overflow: edit ? (record && record.file_name !== '' ? 'scroll' : (selectedFile ? 'scroll' : 'hidden')) : selectedFile ? 'scroll' : 'hidden', 
        overflow: 'scroll',
        borderRadius: '4px', 
        border: '1px solid #DADAE6'}}>
            {!isMobile && <Group style={{width: '100%', position: 'absolute', right: '34px'}} position='right'>
                {this.renderPostalEmailButtonGroup()}
            </Group>}
            
            {/* <FilePreview
            file={null}
            /> */}
            {!loader ?
            <Group position='center' style={{marginTop: !isMobile && '50px', marginBottom: !isMobile && '50px', height: '100%', marginLeft: '0px'}}>
                {selectedView === 'postal' ?
                allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled ?
                messagePreviewPdf &&
                  <div style={{display: 'flex', backgroundColor:'grey', justifyContent: 'center', alignItems: 'center', padding: 2}}>
                    <Stack>
                        <MyPdfViewer file={messagePreviewPdf} isMobile={isMobile}/>
                        {ubPdfUrl && <MyPdfViewer file={ubPdfUrl} isMobile={isMobile}/>}
                        {/* {messageData.utilityBillingId &&
                        <>
                        <PreviewMessagePdfBillingStatement selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated}/>
                        {(propertySelected.propertyType !== 'CONDOMINIUM' && meterReadingsSwitch) && <PreviewMessagePdfMeterReadingsAndConsumptions meterReadingsSwitch={meterReadingsSwitch} selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated} //setReadings={(readings)=> this.setState({readingsAvailable: readings.length > 0 ? true : false})}
                        />}
                        {propertySelected.propertyType !== 'CONDOMINIUM' && (costSplitWarmWaterAndHeatingSwitch || compositionOfHeatingCostsSwitch || splitBaseConsumptionCostsSwitch) &&
                        <PreviewMessagePdfHeatingInformation costSplitWarmWaterAndHeatingSwitch={costSplitWarmWaterAndHeatingSwitch} compositionOfHeatingCostsSwitch={compositionOfHeatingCostsSwitch} splitBaseConsumptionCostsSwitch={splitBaseConsumptionCostsSwitch} selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated}/>}
                        </>} */}
                    </Stack>
                  </div>
                  // :
                  // <>
                  // <div>
                  //   <Stack>
                  //       <PreviewMessagePdf isMobile={isMobile} messageData={messageData} selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} senderAddress={senderAddress} billingPeriod={billingPeriod} rentalUnits={rentalUnits} propertySelected={propertySelected} selectedBillingStatement={selectedBillingStatement} accountData={accountData}/>  
                  //       {messageData.utilityBillingId &&
                  //       <>
                  //       <PreviewMessagePdfBillingStatement selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated}/>
                  //       {meterReadingsSwitch && <PreviewMessagePdfMeterReadingsAndConsumptions meterReadingsSwitch={meterReadingsSwitch} selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated} //setReadings={(readings)=> this.setState({readingsAvailable: readings.length > 0 ? true : false})}
                  //       />}
                  //       {(costSplitWarmWaterAndHeatingSwitch || compositionOfHeatingCostsSwitch || splitBaseConsumptionCostsSwitch) &&
                  //       <PreviewMessagePdfHeatingInformation costSplitWarmWaterAndHeatingSwitch={costSplitWarmWaterAndHeatingSwitch} compositionOfHeatingCostsSwitch={compositionOfHeatingCostsSwitch} splitBaseConsumptionCostsSwitch={splitBaseConsumptionCostsSwitch} selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement} heatingFilter={ubData.heatingCostsActivated}/>}
                  //       </>}
                  //   </Stack>
                  // </div>
                  // </>
                    :
                    <Stack style={{background: 'white', width: '700px', marginTop: '150px', boxShadow: '0px 1px 2px #000'}} justify='center'>
                        <EmptyStateContainer
                        heading={'Briefversand ist für diesen Empfänger deaktiviert.'}
                        subheading={'Aktivieren Sie Briefversand in der Empfängerauswahl, um diese Vorschau anzuzeigen.'}
                        icon={MessagesIcon}
                        showBtn={false}
                        styleMainContainer={{marginBottom: '90px'}}
                        />
                    </Stack>
                    
                :
                selectedView === 'email' ?
                allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isEmailEnabled ?
                    <Stack>
                        <PreviewMessageEmail 
                        messageData={messageData} 
                        selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
                        senderEmail={senderEmail} 
                        senderProfile={this.props.profile} 
                        billingPeriod={billingPeriod} 
                        rentalUnits={rentalUnits} 
                        propertySelected={propertySelected} 
                        selectedBillingStatement={selectedBillingStatement} 
                        accountData={accountData}
                        meterReadingsSwitch={meterReadingsSwitch}
                        compositionOfHeatingCostsSwitch={compositionOfHeatingCostsSwitch}
                        costSplitWarmWaterAndHeatingSwitch={costSplitWarmWaterAndHeatingSwitch}
                        splitBaseConsumptionCostsSwitch={splitBaseConsumptionCostsSwitch}
                        energyConsumptionCO2EmissionsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyConsumptionCO2EmissionsSwitch} 
                        compareHeatingConsumptionBarChartSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compareHeatingConsumptionBarChartSwitch} 
                        energyLevelsInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyLevelsInfoSwitch} 
                        arbitraryBoardInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : arbitraryBoardInfoSwitch}
                        energyAdviceCenterInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyAdviceCenterInfoSwitch}
                        co2TaxInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : ((dayjs(ubData?.billingPeriod?.endDate).year() >= 2023) && ubData?.isCO2TaxActivated) ? co2TaxInfoSwitch : false}
                        heatingFilter={ubData?.heatingCostsActivated}
                        //vatSwitch={!ubData?.isVATHidden}
                        //laborCostSwitch={!ubData?.isLaborCostHidden}
                        senderAddress={senderAddress}  
                        rentalPayment={rentalPayment} 
                        rents={rents} 
                        profile={profile} 
                        tenantRemainingRentObject={tenantRemainingRentObject}
                        laborCostSwitch={laborCostSwitch}
                        vatSwitch={vatSwitch}
                        isPreTaxInfoHidden={isPreTaxInfoHidden}
                        currentSubscription={currentSubscription}
                        />
                        {/* {messageData.utilityBillingId &&
                        <>
                            <PreviewMessagePdfBillingStatement selectedBillingStatement={selectedBillingStatement}/>
                            <PreviewMessagePdfMeterReadingsAndConsumptions selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement}/>
                            <PreviewMessagePdfHeatingInformation selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement}/>
                        </>} */}
                        
                    </Stack>
                    : 
                    <Stack style={{background: 'white', width: '700px', marginTop: '150px', boxShadow: '0px 1px 2px #000'}} justify='center'>
                        <EmptyStateContainer
                        heading={'Emailversand ist für diesen Empfänger deaktiviert.'}
                        subheading={'Aktivieren Sie Emailversand in der Empfängerauswahl, um diese Vorschau anzuzeigen.'}
                        icon={MessagesIcon}
                        showBtn={false}
                        styleMainContainer={{marginBottom: '90px'}}
                        />
                    </Stack>
                  :
                  selectedView === 'tenantPortal' &&
                  allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isHiddenForTenantPortal === false ?
                    <Stack>
                        <PreviewMessageTenantPortal 
                        messageData={messageData} 
                        selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
                        senderEmail={senderEmail} 
                        senderProfile={this.props.profile} 
                        billingPeriod={billingPeriod} 
                        rentalUnits={rentalUnits} 
                        propertySelected={propertySelected} 
                        selectedBillingStatement={selectedBillingStatement} 
                        accountData={accountData}
                        meterReadingsSwitch={meterReadingsSwitch}
                        compositionOfHeatingCostsSwitch={compositionOfHeatingCostsSwitch}
                        costSplitWarmWaterAndHeatingSwitch={costSplitWarmWaterAndHeatingSwitch}
                        splitBaseConsumptionCostsSwitch={splitBaseConsumptionCostsSwitch}
                        energyConsumptionCO2EmissionsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyConsumptionCO2EmissionsSwitch} 
                        compareHeatingConsumptionBarChartSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compareHeatingConsumptionBarChartSwitch} 
                        energyLevelsInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyLevelsInfoSwitch} 
                        arbitraryBoardInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : arbitraryBoardInfoSwitch}
                        energyAdviceCenterInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyAdviceCenterInfoSwitch}
                        co2TaxInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : ((dayjs(ubData?.billingPeriod?.endDate).year() >= 2023) && ubData?.isCO2TaxActivated) ? co2TaxInfoSwitch : false}
                        heatingFilter={ubData?.heatingCostsActivated}
                        //vatSwitch={!ubData?.isVATHidden}
                        //laborCostSwitch={!ubData?.isLaborCostHidden}
                        senderAddress={senderAddress}  
                        rentalPayment={rentalPayment} 
                        rents={rents} 
                        profile={profile} 
                        tenantRemainingRentObject={tenantRemainingRentObject}
                        laborCostSwitch={laborCostSwitch}
                        vatSwitch={vatSwitch}
                        isPreTaxInfoHidden={isPreTaxInfoHidden}
                        currentSubscription={currentSubscription}
                        />
                        {/* {messageData.utilityBillingId &&
                        <>
                            <PreviewMessagePdfBillingStatement selectedBillingStatement={selectedBillingStatement}/>
                            <PreviewMessagePdfMeterReadingsAndConsumptions selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement}/>
                            <PreviewMessagePdfHeatingInformation selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} selectedBillingStatement={selectedBillingStatement}/>
                        </>} */}
                        
                    </Stack>
                    : 
                    <Stack style={{background: 'white', width: '700px', marginTop: '150px', boxShadow: '0px 1px 2px #000'}} justify='center'>
                        <EmptyStateContainer
                        heading={'Die Veröffentlichung im Mieterportal wurde für diesen Empfänger deaktiviert.'}
                        subheading={'Aktivieren Sie Mieterportal in der Empfängerauswahl, um diese Vorschau anzuzeigen.'}
                        icon={MessagesIcon}
                        showBtn={false}
                        styleMainContainer={{marginBottom: '90px'}}
                        />
                    </Stack>
                }
            </Group>
            :
            <Stack style={{width: '100%', height: '680px'}} align='center' justify={'center'}>
                <Loader size='lg' variant='bars' color={'#e3e3fc'} />
            </Stack>
            }
        </div>
      </Skeleton>
      
    )
  }
  
  render() {
    const { open, toggle, className, allRecipients, messageData, senderAddress, senderEmail, billingPeriod, rentalUnits, propertySelected, accountData, isMobile, profile, currentSubscription} = this.props;
    const { imgScale, selectedRecipient, selectedView, selectedBillingStatement, loader, meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, vatSwitch, laborCostSwitch, energyConsumptionCO2EmissionsSwitch, compareHeatingConsumptionBarChartSwitch, energyLevelsInfoSwitch, arbitraryBoardInfoSwitch, energyAdviceCenterInfoSwitch, co2TaxInfoSwitch, ubData, messagePreviewPdf, ubPdfUrl, rentalPayment, rents, tenantRemainingRentObject, firstLoader, loaderRecipientId, isPreTaxInfoHidden } = this.state;

    console.log('rentalUnits: ', rentalUnits);
    console.log('selectedBillingStatement_565: ', selectedBillingStatement, allRecipients);
    console.log('ubData: ', ubData);
    console.log('propertySelected: ', propertySelected);

    console.log('ubPdfUrl: ', ubPdfUrl, messageData);

    console.log('selectedBillingStatement_64637: ', selectedBillingStatement);

    console.log('tenantRemainingRentObject: ', tenantRemainingRentObject);

    return (
      <>
        <CustomModal
          heading={'Vorschauansicht'}
          headingClassName='preview-message-heading'
          open={open}
          toggle={() => {
            toggle();
          }}
          className={`${className} ${isMobile ? 'message-preview-main-mobile-modal' : 'message-preview-main-modal'}`}
          style={{minHeight: '850px', overflow: 'none'}}
        >
            {isMobile ?
            <Stack>
              <Select
              label={'Empfänger'}
              searchable
              data={allRecipients.map((recipient)=> {return {...recipient, label: recipient.name, value: recipient.id}})}
              zIndex={9999999}
              onChange={(value)=> this.handleRecipientChange(value)}
              value={selectedRecipient}
              />
              {this.renderPostalEmailButtonGroup()}
              {this.renderMessagePreviewBox()}
            </Stack>
            :
            <Group style={{width: '100%'}} position='apart' align={'flex-start'} noWrap>
                <Stack style={{width: '25%'}}>
                    <Group>
                        <span className='add-allocation-sub-headings'>Empfänger</span>
                    </Group>    
                    <Stack className='preview-recipients-list' spacing={5}>
                        {allRecipients.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map((recipient)=>
                        <Skeleton visible={firstLoader}>
                            <Group className={`${(loader && loaderRecipientId !== recipient.id) ? 'preview-recipients-list-item-disabled' : 'preview-recipients-list-item'} ${recipient.id === selectedRecipient && !firstLoader && 'preview-selected-recipient'}`} onClick={()=> !firstLoader && !loader && this.handleRecipientChange(recipient.id)} position='apart'>
                                  <Group spacing={8} noWrap>
                                      <Group style={{background: '#454576', width: '22px', borderRadius: '120px', minWidth: '22px'}} position='center' align={'center'}>
                                          <span style={{textTransform: 'capitalize', color: 'white'}}>{recipient.name[0]}</span>
                                      </Group>
                                      <span>{recipient.name}</span>
                                  </Group>
                                  {(loader && loaderRecipientId === recipient.id) && <Loader size={'xs'}/>}
                            </Group>
                          </Skeleton>
                        )}
                    </Stack>
                </Stack>
                
                <Stack style={{width: '100%'}}>
                  {this.renderMessagePreviewBox()}
                </Stack>
            </Group>}
            <Group style={{width: '100%', marginTop: '20px'}} position='right'>
                <div>
                <ColorButton
                text={'Schließen'}
                className={'gray-btn'}
                onClick={()=> toggle()}
                />
                </div>
            </Group>
        </CustomModal>
        {messagePreviewPdf === null && messageData && selectedView === 'postal' && allRecipients.find((recipient)=> recipient.id === selectedRecipient)?.isPostEnabled && //messageData.utilityBillingId ? (messagePreviewPdf === null && ubPdfUrl === null) : messagePreviewPdf === null &&
        <div id='message-divs-to-print' style={{marginTop: 10000}}>
          {(messageData.utilityBillingId ? selectedBillingStatement : messageData.templateType === 'RentalPayment' ? (rentalPayment !== null && rents.length > 0 && tenantRemainingRentObject !== null) : true) && 
          <div style={{visibility: 'hidden'}}>
            <div id='messageDivToPrint'>
              <Stack>
                  <PreviewMessagePdf 
                  isMobile={isMobile} 
                  messageData={messageData} 
                  selectedRecipient={allRecipients.find((recipient)=> recipient.id === selectedRecipient)} 
                  senderAddress={senderAddress} 
                  billingPeriod={billingPeriod} 
                  rentalUnits={rentalUnits} 
                  rentalPayment={rentalPayment} 
                  rents={rents} 
                  propertySelected={propertySelected} 
                  selectedBillingStatement={selectedBillingStatement} 
                  accountData={accountData} 
                  profile={profile} 
                  tenantRemainingRentObject={tenantRemainingRentObject}
                  vatSwitch={vatSwitch}
                  isPreTaxInfoHidden={isPreTaxInfoHidden}
                  currentSubscription={currentSubscription}
                  //multipleTenants={multipleTenants}
                  />  
              </Stack>
            </div>
          </div>}
          {messageData.utilityBillingId && selectedBillingStatement &&
          <div>
            <UtilityBillingTemplate2 
            id={selectedBillingStatement?.id}
            messagePreview={true} 
            meterReadingsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : meterReadingsSwitch} 
            compositionOfHeatingCostsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compositionOfHeatingCostsSwitch} 
            costSplitWarmWaterAndHeatingSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : costSplitWarmWaterAndHeatingSwitch} 
            splitBaseConsumptionCostsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : splitBaseConsumptionCostsSwitch} 
            vatSwitch={vatSwitch} 
            laborCostSwitch={laborCostSwitch} 
            energyConsumptionCO2EmissionsSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyConsumptionCO2EmissionsSwitch} 
            compareHeatingConsumptionBarChartSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : compareHeatingConsumptionBarChartSwitch} 
            energyLevelsInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyLevelsInfoSwitch} 
            arbitraryBoardInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : arbitraryBoardInfoSwitch}
            energyAdviceCenterInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : energyAdviceCenterInfoSwitch}
            co2TaxInfoSwitch={propertySelected.propertyType === 'CONDOMINIUM' ? false : ((dayjs(ubData?.billingPeriod?.endDate).year() >= 2023) && ubData?.isCO2TaxActivated) ? co2TaxInfoSwitch : false}
            heatingFilter={propertySelected.propertyType === 'CONDOMINIUM' ? false : ubData?.heatingCostsActivated}
            isPreTaxInfoHidden={isPreTaxInfoHidden}
            getPdfUrl={(link)=> this.setState({ubPdfUrl: link, loader: false, loaderRecipientId: null}, ()=> {
              if(messageData.utilityBillingId){
                this.downloadPdf();
              }
              // let mainMessageDivsToPrint= document.getElementById('message-divs-to-print');
              // mainMessageDivsToPrint.style.display= 'none';
            })} />
          </div>}
        </div>}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    profile: state.generalReducer.profile,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    accountData: state.generalReducer.accountData,
    currentSubscription: state.generalReducer.currentSubscription
  };
};

export default connect(mapStateToProps)(MessageTemplatesModal);
