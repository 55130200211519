import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Group, Select, Stack, Accordion, Loader, Popover, Box, Overlay, TextInput } from '@mantine/core';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import Up from '../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../assets/images/module/general/SelectArrowDown.svg';
import DatePickerIcon from '../../../../assets/images/module/general/DatePickerIcon.svg'
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import XlsxIcon from '../../../../assets/images/module/general/XlsxIcon.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import Receipt from '../../../../assets/images/module/general/ReceiptDummy.png';
import {
  changeCurrentProperty,
  setSelectedTaxYearForTaxDeclaration
} from '../../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import accounting from 'accounting';
import { ColorButton, EmptyStateContainer, ImageModal, ReceiptDetailModal, ShowModal } from '../../../../neoverv';
import EmptyIcon from '../../../../assets/images/EmptyReciept.svg';
import * as XLSX from 'xlsx';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import { config } from '../../../../neoverv/config/Setting';
import BulkDownloadForReceiptsModal from '../../../../neoverv/components/Module/General/CustomModal/BulkDownloadForReceiptsModal';
import DisplayModalForMobileView from '../../../../neoverv/components/Module/General/CustomModal/DisplayModalForMobileView';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var langnewKeys = Setting[Setting.language_default].newKeys;

const MOBILE_SIZE = 500;
const TABLET_SIZE = 1420;

const {checkProperty} = Autoload.MainHelper;
export class TaxDeclarationScreen extends Component {
  openTenantSelectionModalRef = null;

  constructor(props) {
    super(props);
    dayjs.locale('de');
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      dropdownIcon: Down,
      endDate: dayjs(),
      startDate: dayjs('01.01.2020'),
      selectedBillingPeriod: null,
      billingPeriods: [],
      rentalUnits: [],
      receipts: [],
      utilityBillings: [],
      annualYearOptions: [],
      selectedAnnualYear: null,
      receiptExpenditureTaxCostTypesInUse: [
        'Absetzung für Abnutzung für Gebäude',
        'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter',
        'Schuldzinsen',
        'Geldbeschaffungskosten',
        'Renten, dauernde Lasten',
        'Erhaltungsaufwendungen',
        'Laufende Betriebskosten',
        'Verwaltungskosten',
        'An Finanzamt gezahlte Umsatzsteuer',
        'Sonstiges'
      ],
      receiptExpenditureTaxCostTypesInUseOriginalData: [
        'Absetzung für Abnutzung für Gebäude',
        'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter',
        'Schuldzinsen',
        'Geldbeschaffungskosten',
        'Renten, dauernde Lasten',
        'Erhaltungsaufwendungen',
        'Laufende Betriebskosten',
        'Verwaltungskosten',
        'An Finanzamt gezahlte Umsatzsteuer',
        'Sonstiges'
      ],
      receiptIncomeTaxCostTypesInUse: [
        'Erstattete Vorauszahlungen',
        'Erhaltene Nachzahlungen',
        'Vereinnahmte Kautionen / Mieten aus Vorjahren',
        'Vom Finanzamt erstattete Umsatzsteuer',
        'Öffentliche Zuschüsse'
      ],
      receiptIncomeTaxCostTypesInUseOriginalData: [
        'Erstattete Vorauszahlungen',
        'Erhaltene Nachzahlungen',
        'Vereinnahmte Kautionen / Mieten aus Vorjahren',
        'Vom Finanzamt erstattete Umsatzsteuer',
        'Öffentliche Zuschüsse'
      ],
      referenceReceiptTaxCostType: null,
      receiptExpenditureTaxCostTypesInUseOpenedAccordions: [],
      receiptIncomeTaxCostTypesInUseOpenedAccordions: [],
      setOpenedAccordionsIncomeRentals: [],
      expandAppendixRowTaxType: null,
      incomeTenantsAccordionState: {
        0: false,
        1: false,
        2: false
      },
      incomeReceiptsAccordionState: {},
      expenditureReceiptsAccordionState: {},
      loaderBP: false,
      loaderRentalUnit: false,
      loaderReceipts: false,
      loaderUB: false,
      exportAnlageVOpen: false,
      openAllExpenditureReceiptsTables: false,
      openAllIncomeReceiptsTables: false,
      openAllTenantsRentalTables: false,
      openReceiptDetailModal: false,
      selectedReceiptRecord: null,
      openReceiptImageModal: false,
      editReceiptOpen: false,
      selectedReceipts: [],
      openBulkReceiptDownloadModal: false,
      openDisplayModalForMobileView: false,
      headingForMobileModal: null,
      contentForMobileModal: null,
      rentalPayments: []
    };

  }

  componentDidMount(){
    console.log('componentDidMount');
    const {receiptIncomeTaxCostTypesInUse, receiptExpenditureTaxCostTypesInUse} = this.state;
    let {incomeReceiptsAccordionState, expenditureReceiptsAccordionState} = this.state;

    receiptIncomeTaxCostTypesInUse.map((_, index)=> Object.assign(incomeReceiptsAccordionState, {[index]: false}));
    receiptExpenditureTaxCostTypesInUse.map((_, index)=> Object.assign(expenditureReceiptsAccordionState, {[index]: false}));

    this.setState({
      incomeReceiptsAccordionState, 
      expenditureReceiptsAccordionState,
      loaderBP: true,
      loaderRentalUnit: true,
      loaderReceipts: true,
      loaderUB: true
    });

    GlobalServices._getBilingPeriodsByProperty(this, '_handleBillingPeriodResponse', this.props.propertySelected);
    
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  getRentalPayments=()=>{
    this.setState({loaderRentalUnit: true});
    const {selectedAnnualYear} = this.state
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/property/' + this.props.propertySelected.id + 
      '?startDate='+ dayjs(`${selectedAnnualYear}-01-01`).format('YYYY-MM-DDT00:00:00') + 
      '&endDate='+ dayjs(`${selectedAnnualYear}-12-31`).format('YYYY-MM-DDT00:00:00'),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_rental_payments',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_rental_payments(response){
    let {rentalUnits} = this.state;
    console.log('handle_get_rental_payments: ', rentalUnits);
    if(response.status && response.statuscode === 200){
      // response.result.map((rentalPayment)=> {
      //   let tenant = null;
      //   rentalUnits.map((rentalUnit)=>{
      //     let dataFound = rentalUnit.tenants.find((tenant)=> tenant.id === rentalPayment.tenantId);
      //     tenant = dataFound ? {...dataFound, rentalUnitName: rentalUnit.name} : tenant ? tenant : null;
      //   });
      //   rentalPayments.push({
      //     ...rentalPayment,
      //     tenant
      //   });
      // });

      rentalUnits= rentalUnits.map((rentalUnit)=> ({...rentalUnit, tenants: rentalUnit.tenants.map((tenant)=> {
        let tenantRelatedRentalPayments= response.result.filter((rentalPayment)=> rentalPayment.tenantId === tenant.id);
        tenant.rents = tenantRelatedRentalPayments;
        return tenant;
      })}));
      this.setState({rentalUnits, loaderRentalUnit: false});
    }

    setTimeout(() => {
      this.setState({loaderRentalUnit: false});  
    }, 600);
  }

  _handleUtilityBillingsResponse(response){
    if(response.statuscode === 200 && response.status){
      this.setState({utilityBillings: response.result});
    }
    this.setState({loaderUB: false});
  }

  filterRelevantReceipts=()=>{
    let {receiptExpenditureTaxCostTypesInUse, receiptIncomeTaxCostTypesInUse} = this.state;
    const {selectedAnnualYear, receipts, receiptExpenditureTaxCostTypesInUseOriginalData, receiptIncomeTaxCostTypesInUseOriginalData} = this.state;
    receiptExpenditureTaxCostTypesInUse = receiptExpenditureTaxCostTypesInUseOriginalData.filter((taxCostType)=> receipts.find((receipt)=> (dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear) && receipt.taxCostType	=== (taxCostType === 'Absetzung für Abnutzung für Gebäude' ? 'AfA Gebäude' : taxCostType === 'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter' ? 'AfA bewegliche Wirtschaftsgüter' : taxCostType === 'Sonstiges' ? 'Sonstige Kosten' : taxCostType)) !== undefined);
    receiptIncomeTaxCostTypesInUse = receiptIncomeTaxCostTypesInUseOriginalData.filter((taxCostType)=> receipts.find((receipt)=> (dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear) && receipt.taxCostType	=== taxCostType) !== undefined);
    this.setState({
      receiptExpenditureTaxCostTypesInUse, 
      receiptIncomeTaxCostTypesInUse
    });
  }

  _handleReceiptsResponse(response){
    if(response.statuscode === 200 && response.status){
      this.setState({
        receipts: response.result.map((receipt)=> ({...receipt, costs: receipt.costs}))
      }, ()=> this.filterRelevantReceipts());
    }
    this.setState({loaderReceipts: false});

    GlobalServices._getUtilityBillingsByProperty(this, '_handleUtilityBillingsResponse', this.props.propertySelected);
  }

  _handleBillingPeriodResponse(response){
    const {selectedTaxYearForTaxDeclaration} = this.props;
    if(response.status && response.statuscode === 200){
      this.setState({
        billingPeriods: response.result
        .map((billingPeriod)=> ({
          ...billingPeriod, 
          startDate: dayjs(billingPeriod.startDate).format('DD.MM.YYYY'), 
          endDate: dayjs(billingPeriod.endDate).format('DD.MM.YYYY'),
          value: billingPeriod,
          label: `${dayjs(billingPeriod.startDate).format('DD.MM.YYYY')} - ${dayjs(billingPeriod.endDate).format('DD.MM.YYYY')}`
        })),
        startDate: dayjs(response.result.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)))[0].startDate)
      }, ()=> this.setAnnualYearOptions());
    }else{
      this.setState({loaderUB: false});
    }
    GlobalServices._getRentalUnitsByProperty(this, '_handleRentalUnitsResponse', this.props.propertySelected);
  }

  setAnnualYearOptions=()=>{
    const {startDate, endDate} = this.state;
    const {selectedTaxYearForTaxDeclaration} = this.props;
    let {annualYearOptions} = this.state;
    let arrayOfYears=[];

    for (let year = dayjs(startDate).get('year'); year <= dayjs(endDate).get('year'); year++) arrayOfYears.push(year);
    console.log('setAnnualYearOptions: ', arrayOfYears);

    annualYearOptions= arrayOfYears.sort((a,b)=> b - a).map((year)=> ({
      label: `01.01.${year} - 31.12.${year}`, 
      value: year.toString(),
      optionData: {
        startDate: `01.01.${year}`, 
        endDate: `31.12.${year}`,
        id: year.toString()
      }
    }));
    this.setState({
      annualYearOptions,
      selectedAnnualYear: selectedTaxYearForTaxDeclaration ? selectedTaxYearForTaxDeclaration : annualYearOptions[1]?.value,
      loaderBP: false
    }, ()=> this.props.setSelectedTaxYearForTaxDeclaration(selectedTaxYearForTaxDeclaration ? selectedTaxYearForTaxDeclaration : annualYearOptions[1]?.value));
  }

  _handleRentalUnitsResponse(response){
    if(response.statuscode === 200 && response.status){
      this.setState({rentalUnits: response.result},()=> {
        response.result.map((rentalUnit, index)=> {
          GlobalServices._getTenantsByRentalUnitID(this, '_handleTenantsResponse', {rentalUnit, eol: response.result.length === (index+1)});
        })
      });
    }

    GlobalServices._getReceiptsByProperty(this, '_handleReceiptsResponse', this.props.propertySelected);
  }

  _handleTenantsResponse(response){
    let {rentalUnits} = this.state;
    if(response.statuscode === 200 && response.status){
      rentalUnits= rentalUnits.map((rentalUnit)=> {
        if(rentalUnit.id === response.other.rentalUnit.id){
          rentalUnit.tenants= response.result;
        }
        return rentalUnit;
      });

      this.setState({rentalUnits}, ()=> {
        if(response.other.eol){
          this.getRentalPayments();
        }
        // response.result.map((tenant, index)=> {
        //   GlobalServices._getRentsByTenantId(this, '_handleRentsResponse', {tenant, eol: response.result.length === (index+1)});
        // })
      });
    }
  }

  _handleRentsResponse(response){
    let {rentalUnits} = this.state;
    if(response.statuscode === 200 && response.status){
      rentalUnits= rentalUnits.map((rentalUnit)=> {
        if(rentalUnit.id === response.other.tenant.rentalUnitId){
          rentalUnit.tenants= rentalUnit?.tenants?.map((tenant)=> {
            if(tenant.id === response.other.tenant.id){
              tenant.rents= response.result;
            }
            return tenant;
          });
        }
        return rentalUnit;
      });

      this.setState({rentalUnits});
    }
    setTimeout(() => {
      this.setState({loaderRentalUnit: response.other.eol && false});  
    }, 600);
  }

  handleChange = (event) => {
    this.setState({selectedDateRange: event.target.value});
  };

  handleDateRangeChange = (newDate, type) => {
    console.log('handleDateRangeChange: ', newDate, type);

    let newdateRangeOptions = this.state.dateRangeOptions.map((range, index)=> {
        if(index === 2){
          if(type === 'start'){
            range.date = {
              ...range.date,
              start: newDate
            };
          }else if(type === 'end'){
            range.date = {
              ...range.date,
              end: new Date(moment(newDate).endOf('M'))
            };
          }
        return range;
        }else{
        return range;
        }
    });
    
    this.setState({ dateRangeOptions: newdateRangeOptions });
  };


  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  renderTableHeader=()=>{
    return(
      <TableHead>
        <TableRow>
          <TableCell className='tax-declaration-table-header' width={'5%'} style={{textAlign: 'right'}}>Zeile</TableCell>
          <TableCell className='tax-declaration-table-header' width={'40%'}>Schlüssel</TableCell>
          <TableCell className='tax-declaration-table-header'>Wert</TableCell>
          <TableCell className='tax-declaration-table-header' width={'5%'}/>
          <TableCell className='tax-declaration-table-header' width={'5%'}/>
        </TableRow>
      </TableHead>
    )
  }

  dateIsSameOrIsAfter=(dateA, dateB)=>{
    console.log('dateIsSameOrIsAfter: ', dayjs(dateA).format('DD.MM.YYYY'), dayjs(dateB).format('DD.MM.YYYY'), dayjs(dateA).isAfter(dayjs(dateB)) || dayjs(dateA).isSame(dayjs(dateB)));
    return (dayjs(dateA).isAfter(dayjs(dateB)) || dayjs(dateA).isSame(dayjs(dateB)));
  }

  dateIsSameOrIsBefore=(dateA, dateB)=>{
    console.log('dateIsSameOrIsBefore: ', dateA, dateB, dayjs(dateA).isBefore(dayjs(dateB, 'DD.MM.YYYY')) || dayjs(dateA).isSame(dayjs(dateB, 'DD.MM.YYYY')));
    return (dayjs(dateA).isBefore(dayjs(dateB)) || dayjs(dateA).isSame(dayjs(dateB)));
  }

  dateIsBefore=(dateA, dateB, number)=>{
    console.log(number+'dateIsBefore: ', dateA, dateB, dayjs(dateA).isBefore(dayjs(dateB, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss")));
    return (dayjs(dateA).isBefore(dayjs(dateB, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss")));
  }

  dateIsAfter=(dateA, dateB, number)=>{
    console.log(number+'dateIsAfter: ', dayjs(dateA).format('DD.MM.YYYY'), dateB, dayjs(dateA).isAfter(dayjs(dateB, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss")));
    return (dayjs(dateA).isAfter(dayjs(dateB, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss")));
  }

  renderCopyAndJumpTo=(copyValue, navigateTo, referenceTo, type=null)=>  {
    const {setOpenedAccordionsIncomeRentals, isMobile} = this.state;

    const renderNavigate=()=>{
      return(
      (navigateTo || referenceTo) ?
      <NavigateNextIcon 
      style={{
        color: '#5655FE',
        fontSize: '20px',
        cursor: 'pointer'
      }}
      onClick={()=> {
        if(navigateTo){
          this.props.history.push(navigateTo);
        }else if(referenceTo){
          if(type === 'rentalIncome'){
            let {incomeTenantsAccordionState} = this.state;
            if(referenceTo === 'total-private-tenants-net-rent'){
              if(isMobile){
                this.setMobileViewStates(true, this.renderPrivateTenantAppendixTable(), 'Mieteinnahmen Wohnungen');
              }else{
                Object.assign(incomeTenantsAccordionState, {0: true, 1: false, 2: false});
              }
            }else if(referenceTo === 'total-commercial-tenants-net-rent'){
              if(isMobile){
                this.setMobileViewStates(true, this.renderCommercialTenantAppendixTable(), 'Mieteinnahmen andere Räume');
              }else{
                Object.assign(incomeTenantsAccordionState, {0: false, 1: true, 2: false});
              }
            }else if(referenceTo === 'total-umlagen'){
              if(isMobile){
                this.setMobileViewStates(true, this.renderUmlagenTable(), 'Umlagen');
              }else{
                Object.assign(incomeTenantsAccordionState, {0: false, 1: false, 2: true}); 
              }
            }
            this.setState({incomeTenantsAccordionState, setOpenedAccordionsIncomeRentals: setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === (referenceTo === 'total-private-tenants-net-rent' ? 'privateTenants' : referenceTo === 'total-umlagen' ? 'prePayments' : 'commercialTenants')) !== undefined ? setOpenedAccordionsIncomeRentals.filter((openedAccordion)=> openedAccordion !== (referenceTo === 'total-private-tenants-net-rent' ? 'privateTenants' : referenceTo === 'total-umlagen' ? 'prePayments' : 'commercialTenants')) : [(referenceTo === 'total-private-tenants-net-rent' ? 'privateTenants' : referenceTo === 'total-umlagen' ? 'prePayments' : 'commercialTenants')]}, ()=> document?.getElementById(referenceTo)?.scrollIntoView({ block: 'start',  behavior: 'smooth' }));
          }else{
            const {receiptIncomeTaxCostTypesInUse, receiptExpenditureTaxCostTypesInUse} = this.state;
            let {incomeReceiptsAccordionState, expenditureReceiptsAccordionState} = this.state;

            !isMobile && receiptIncomeTaxCostTypesInUse.map((value, index)=> Object.assign(incomeReceiptsAccordionState, {[index]: value === referenceTo}));
            !isMobile && receiptExpenditureTaxCostTypesInUse.map((value, index)=> Object.assign(expenditureReceiptsAccordionState, {[index]: value === referenceTo}));

            if(!isMobile && type){
              this.setState({[`${type}OpenedAccordions`]: this.state[`${type}OpenedAccordions`].find((openedAccordion)=> openedAccordion === referenceTo) !== undefined ? this.state[`${type}OpenedAccordions`].filter((openedAccordion)=> openedAccordion !== referenceTo) : [referenceTo]})
            }

            !isMobile && this.setState({incomeReceiptsAccordionState, expenditureReceiptsAccordionState});
            
            this.setState({expandAppendixRowTaxType: !isMobile && referenceTo}, ()=> document?.getElementById(referenceTo)?.scrollIntoView({ block: 'start',  behavior: 'smooth' }))
            if(isMobile){
              this.setMobileViewStates(true, this.renderReceiptsTable(referenceTo), referenceTo);
            }
          }
        }
      }}/>
      :
      !isMobile && '-')
    }
    return (  
      isMobile ?
      <Group spacing={8} align='center' position='center' noWrap>
        <FileCopyIcon 
        style={{
          color: '#5655FE',
          fontSize: '15px',
          cursor: 'pointer'
        }}
        onClick={()=> {
          navigator.clipboard.writeText(copyValue);
          showNotification({
            message: 'Daten kopiert',
            icon: <img src={GreenTick} alt='green-tick'/>,
            id: 'dataCopied'
        });
        }}/>
        {renderNavigate()}
      </Group>
      :  
      <>
        <TableCell className='tax-declaration-table-body' style={{textAlign: 'center'}}>
          <FileCopyIcon 
          style={{
            color: '#5655FE',
            fontSize: '15px',
            cursor: 'pointer'
          }}
          onClick={()=> {
            navigator.clipboard.writeText(copyValue);
            showNotification({
              message: 'Daten kopiert',
              icon: <img src={GreenTick} alt='green-tick'/>,
              id: 'dataCopied'
          });
          }}/>
        </TableCell>
        <TableCell className='tax-declaration-table-body' style={{textAlign: 'center'}}>
          {renderNavigate()}
        </TableCell>
      </>
    )
  }

  calculateRentsBasedOnMonths=(rent, startDate, endDate, selectedAnnualYearOptionData, number)=>{
    const dayjsStartDate = this.dateIsBefore(startDate, selectedAnnualYearOptionData?.optionData?.startDate, number) ? dayjs(selectedAnnualYearOptionData?.optionData?.startDate, 'DD.MM.YYYY') : dayjs(startDate);
    const dayjsEndDate = endDate ? this.dateIsAfter(endDate, selectedAnnualYearOptionData?.optionData?.endDate, number) ? dayjs(selectedAnnualYearOptionData?.optionData?.endDate, 'DD.MM.YYYY') : dayjs(endDate) : dayjs(selectedAnnualYearOptionData?.optionData?.endDate, 'DD.MM.YYYY');



    const monthsBetween = dayjsEndDate.diff(dayjsStartDate, 'month', true);
    console.log('calculateRentsBasedOnMonths: ', startDate, endDate);
    console.log(`${number}monthsBetween: `, Math.floor(monthsBetween), ', startDates: ', dayjsStartDate.format('DD.MM.YYYY'), ', endDates: ', dayjsEndDate.format('DD.MM.YYYY'), rent, monthsBetween > 0 ? (rent * (Math.floor(monthsBetween)+1)) : 0, this.dateIsAfter(endDate, selectedAnnualYearOptionData?.optionData?.endDate));

    return monthsBetween > 0 ? (rent * (Math.floor(monthsBetween)+1)) : 0;
  }

  renderTableColumns=(number, label, value, loader, copyAndJumpTo, bold=false)=>{
    const {isMobile} = this.state;
    return(
      isMobile ?
      <TableCell colSpan={5}>
        <TextInput
        label={`${number}. ${label}`}
        value={value}
        rightSection={
          loader ?
          <Group noWrap>
            <Loader size={'xs'}/>
          </Group>
          :
          copyAndJumpTo
        }
        classNames={{
          input: 'tax-declaration-input-values',
          rightSection: 'tax-declaration-right-section-values',
        }}
        styles={{
          label: {
            fontWeight: bold && 'bold'
          },
          input: {
            fontWeight: bold && 'bold'
          }
        }}
        />
      </TableCell>
      :
      <>
        <TableCell className='tax-declaration-table-body' style={{textAlign: 'right', fontWeight: bold && 'bold'}}>{number}</TableCell>
        <TableCell className='tax-declaration-table-body' style={{fontWeight: bold && 'bold'}}>{label}</TableCell>
        <TableCell className='tax-declaration-table-body' style={{fontWeight: bold && 'bold'}}>
          {loader ?
          <Group noWrap>
            <Loader size={'xs'}/>
          </Group>
          :
          value}
        </TableCell>
        {copyAndJumpTo}
      </>
    )
  }

  renderTableBody=()=>{
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, loaderBP, loaderReceipts, loaderRentalUnit, loaderUB, isMobile} = this.state;
    console.log('propertySelected: ', propertySelected);
    console.log('rentalUnits_112341: ', rentalUnits);
    console.log('receipts: ', receipts);
    
    const selectedAnnualYearOptionData= annualYearOptions.find((option)=> option.optionData.id === selectedAnnualYear);
    console.log('selectedAnnualYearOptionData: ', selectedAnnualYearOptionData);
    const totalNetRentForPrivateTenants_9= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'private' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const totalNetRentForCommercialTenants_10= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);

    let commercialTenantsTotalNetRentVatAmount_10= totalNetRentForCommercialTenants_10 / 1.19;
    commercialTenantsTotalNetRentVatAmount_10= totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10;

    const advancePayments_13= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? (c.receivedAdvancePayment / 1.19) : c.receivedAdvancePayment), 0) : null), 0) : null), 0);
    const creditTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erstattete Vorauszahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    const debitTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erhaltene Nachzahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    
    const retainedDepositTaxCostTypeReceipts_15= receipts.filter((receipt)=> receipt.taxCostType === 'Vereinnahmte Kautionen / Mieten aus Vorjahren' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    const totalGrossRentForCommercialTenants_17= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent  + c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
    const vatTaxRefundByTaxOffice_18= receipts.filter((receipt)=> receipt.taxCostType === 'Vom Finanzamt erstattete Umsatzsteuer' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    const publicGrants_20 = receipts.filter((receipt)=> receipt.taxCostType === 'Öffentliche Zuschüsse' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    
    const valueFor_33= receipts.filter((receipt)=> receipt.taxCostType === 'AfA Gebäude' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_36= receipts.filter((receipt)=> receipt.taxCostType === 'AfA bewegliche Wirtschaftsgüter' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_37= receipts.filter((receipt)=> receipt.taxCostType === 'Schuldzinsen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_38= receipts.filter((receipt)=> receipt.taxCostType === 'Geldbeschaffungskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_39= receipts.filter((receipt)=> receipt.taxCostType === 'Renten, dauernde Lasten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_40= receipts.filter((receipt)=> receipt.taxCostType === 'Erhaltungsaufwendungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_47= receipts.filter((receipt)=> receipt.taxCostType === 'Laufende Betriebskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_48= receipts.filter((receipt)=> receipt.taxCostType === 'Verwaltungskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_49= receipts.filter((receipt)=> receipt.taxCostType === 'An Finanzamt gezahlte Umsatzsteuer' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_50= receipts.filter((receipt)=> receipt.taxCostType === 'Sonstige Kosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));

    const valueFor_51_22= valueFor_33.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_36.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_37.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_38.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_39.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_40.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_47.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_48.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_49.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_50.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0);


    console.log('check_Total: ', totalNetRentForPrivateTenants_9 , 
    (totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10) , 
    (advancePayments_13
    -
    (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
    +
    debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)))
    ,
    retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) ,
    (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) ,
    vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) ,
    publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0));

    return(
      <TableBody>
        <TableRow>
          {this.renderTableColumns(1, 'Name / Gemeinschaft', accountData.addressCompany, null, this.renderCopyAndJumpTo(accountData.addressCompany, '/profile/companyDetails'))}
        </TableRow>
        {/* New Heading */}
        <TableRow>
          <TableCell className='tax-declaration-table-header' style={{backgroundColor: 'white', border: isMobile && 'none', whiteSpace: 'normal'}} colSpan={5}>
            Lage des Grundstücks / der Eigentumswohnung
          </TableCell>
        </TableRow>
        <TableRow>
          {this.renderTableColumns(4, 'Straße, Hausnummer', propertySelected.propertyAddress, null, this.renderCopyAndJumpTo(propertySelected.propertyAddress, '/property'))}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(5, 'Postleitzahl, Ort', `${propertySelected.propertyZipCode} ${propertySelected.propertyCity}`, null, this.renderCopyAndJumpTo(`${propertySelected.propertyZipCode} ${propertySelected.propertyCity}`, '/property'))}
        </TableRow>
        {/* New Heading */}
        <TableRow>
          <TableCell className='tax-declaration-table-header' style={{backgroundColor: 'white', border: isMobile && 'none', whiteSpace: 'normal'}} colSpan={5}>
            Einkünfte
          </TableCell>
        </TableRow>
        <TableRow>
          {this.renderTableColumns(8, 'Gesamtwohnfläche', `${accounting.formatMoney(rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.space ? a.space : null), 0), '㎡', 2, '.', ',', '%v %s')}`, loaderRentalUnit, this.renderCopyAndJumpTo(accounting.format(rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.space ? a.space : null), 0), 2, '.', ','), '/user'))}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(9, 'Mieteinnahmen für Wohnungen (ohne Umlagen)', accounting.formatMoney(totalNetRentForPrivateTenants_9, '€', 2, '.', ',', '%v %s'), loaderRentalUnit, this.renderCopyAndJumpTo(accounting.formatMoney(totalNetRentForPrivateTenants_9, '€', 2, '.', ',', '%v %s'), null, 'total-private-tenants-net-rent', 'rentalIncome'))}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            10, 
            'Mieteinnahmen für andere Räume (ohne Umlagen)', 
            accounting.formatMoney(totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10, '€', 2, '.', ',', '%v %s'), 
            loaderRentalUnit, 
            this.renderCopyAndJumpTo(accounting.formatMoney(totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10, '€', 2, '.', ',', '%v %s'), null, 'total-commercial-tenants-net-rent', 'rentalIncome')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            13, 
            'Umlagen', 
            accounting.formatMoney(
            advancePayments_13
            +
            (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
            +
            debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0))
            , '€', 2, '.', ',', '%v %s'), 
            (loaderRentalUnit || loaderReceipts), 
            this.renderCopyAndJumpTo(accounting.formatMoney(
            advancePayments_13
            +
            (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
            +
            debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0))
            , '€', 2, '.', ',', '%v %s'), null, 'total-umlagen', 'rentalIncome')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            15, 
            'Mieten aus Vorjahren, vereinnahmte Kautionen', 
            accounting.formatMoney(retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Vereinnahmte Kautionen / Mieten aus Vorjahren', 'receiptIncomeTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            17, 
            'Vereinnahmte Umsatzsteuer', 
            accounting.formatMoney(totalGrossRentForCommercialTenants_17 -(totalGrossRentForCommercialTenants_17 / 1.19), '€', 2, '.', ',', '%v %s'), 
            loaderRentalUnit, 
            this.renderCopyAndJumpTo(accounting.formatMoney(totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19), '€', 2, '.', ',', '%v %s'), null, 'total-commercial-tenants-net-rent', 'rentalIncome')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            18, 
            'Vom Finanzamt erstattete Umsatzsteuer', 
            accounting.formatMoney(vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Vom Finanzamt erstattete Umsatzsteuer', 'receiptIncomeTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            20, 
            'Öffentliche Zuschüsse', 
            accounting.formatMoney(publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Öffentliche Zuschüsse', 'receiptIncomeTaxCostTypesInUse')
          )}
        </TableRow>


        <TableRow>
          {this.renderTableColumns(
            21, 
            'Summe der Einnahmen', 
            accounting.formatMoney(
              totalNetRentForPrivateTenants_9 + 
              (totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10) + 
              (advancePayments_13
              -
              (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              +
              debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)))
              +
              retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
              vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              , '€', 2, '.', ',', '%v %s'
            ), 
            (loaderRentalUnit || loaderReceipts), 
            this.renderCopyAndJumpTo(accounting.formatMoney(
              totalNetRentForPrivateTenants_9 + 
              (totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10) + 
              (advancePayments_13
              -
              (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              +
              debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)))
              +
              retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
              vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              , '€', 2, '.', ',', '%v %s'
            ), null),
            true
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            22, 
            'Summe der Werbungskosten', 
            accounting.formatMoney(valueFor_51_22, '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_51_22, '€', 2, '.', ',', '%v %s'), null),
            true
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            23, 
            'Überschuss', 
            accounting.formatMoney((
              totalNetRentForPrivateTenants_9 + 
              (totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10) + 
              (advancePayments_13
              -
              (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              +
              debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0))) 
              +
              retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
              vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
            ) -
            valueFor_51_22, '€', 2, '.', ',', '%v %s'), 
            (loaderReceipts || loaderRentalUnit), 
            this.renderCopyAndJumpTo(accounting.formatMoney((
              totalNetRentForPrivateTenants_9 + 
              (totalNetRentForCommercialTenants_10 - commercialTenantsTotalNetRentVatAmount_10) + 
              (advancePayments_13
              -
              (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
              +
              debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)))
              +
              retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
              vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
              publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
            ) -
            valueFor_51_22, '€', 2, '.', ',', '%v %s'), null),
            true
          )}
        </TableRow>
        {/* New Heading */}
        <TableRow>
          <TableCell className='tax-declaration-table-header' style={{backgroundColor: 'white', border: isMobile && 'none', whiteSpace: 'normal'}} colSpan={5}>
            Werbungskosten
          </TableCell>
        </TableRow>

        <TableRow>
          {this.renderTableColumns(
            33, 
            'Absetzung für Abnutzung für Gebäude', 
            accounting.formatMoney(valueFor_33.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_33.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Absetzung für Abnutzung für Gebäude', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            36, 
            'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter', 
            accounting.formatMoney(valueFor_36.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_36.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            37, 
            'Schuldzinsen', 
            accounting.formatMoney(valueFor_37.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_37.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Schuldzinsen', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            38, 
            'Geldbeschaffungskosten', 
            accounting.formatMoney(valueFor_38.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_38.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Geldbeschaffungskosten', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            39, 
            'Renten, dauernde Lasten', 
            accounting.formatMoney(valueFor_39.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_39.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Renten, dauernde Lasten', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            40, 
            'Erhaltungsaufwendungen', 
            accounting.formatMoney(valueFor_40.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_40.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Erhaltungsaufwendungen', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            47, 
            'Laufende Betriebskosten', 
            accounting.formatMoney(valueFor_47.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_47.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Laufende Betriebskosten', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            48, 
            'Verwaltungskosten', 
            accounting.formatMoney(valueFor_48.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_48.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Verwaltungskosten', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            49, 
            'An Finanzamt gezahlte Umsatzsteuer', 
            accounting.formatMoney(valueFor_49.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_49.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'An Finanzamt gezahlte Umsatzsteuer', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            50, 
            'Sonstiges', 
            accounting.formatMoney(valueFor_50.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_50.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s'), null, 'Sonstiges', 'receiptExpenditureTaxCostTypesInUse')
          )}
        </TableRow>
        <TableRow>
          {this.renderTableColumns(
            51, 
            'Summe der Werbungskosten', 
            accounting.formatMoney(valueFor_51_22, '€', 2, '.', ',', '%v %s'), 
            loaderReceipts, 
            this.renderCopyAndJumpTo(accounting.formatMoney(valueFor_51_22, '€', 2, '.', ',', '%v %s'), null),
            true
          )}
        </TableRow>
      </TableBody>
    )
  }

  renderReceiptsTable=(taxCostType, type)=>{
    return(
      <TableContainer>
        <Table style={{backgroundColor: '#F7F7FA'}}>
          {this.renderAppendixHeader()}
          {this.renderAppendixBody(taxCostType, type)}
        </Table>
      </TableContainer>
    )
  }

  renderExpenditureReceiptsAppendixRows=(type)=>{
    const {receiptExpenditureTaxCostTypesInUse, referenceReceiptTaxCostType, setOpenedAccordions, receiptExpenditureTaxCostTypesInUseOpenedAccordions, receiptIncomeTaxCostTypesInUseOpenedAccordions, incomeReceiptsAccordionState, expenditureReceiptsAccordionState, openAllExpenditureReceiptsTables, openAllIncomeReceiptsTables, isMobile} = this.state;
    console.log('accordions: ', receiptExpenditureTaxCostTypesInUseOpenedAccordions, receiptIncomeTaxCostTypesInUseOpenedAccordions);
    return(
      <Accordion
      classNames={{
        control: 'tax-declaration-appendix-accordion-control',
        content: 'tax-declaration-appendix-accordion-content',
        icon: 'tax-declaration-appendix-accordion-icon',
        contentInner: 'tax-declaration-appendix-accordion-contentInner'
      }}
      multiple={type === 'receiptExpenditureTaxCostTypesInUse' ? openAllExpenditureReceiptsTables : openAllIncomeReceiptsTables}
      onChange={(state)=> !isMobile && this.setState({[type === 'receiptExpenditureTaxCostTypesInUse' ? 'expenditureReceiptsAccordionState' : 'incomeReceiptsAccordionState']: state})}
      state={type === 'receiptExpenditureTaxCostTypesInUse' ? expenditureReceiptsAccordionState : incomeReceiptsAccordionState}
      >
        {this.state[type].map((taxCostType)=>
        <Accordion.Item
        onClick={()=> !isMobile && this.setState({[`${type}OpenedAccordions`]: this.state[`${type}OpenedAccordions`].find((openedAccordion)=> openedAccordion === taxCostType) !== undefined ? this.state[`${type}OpenedAccordions`].filter((openedAccordion)=> openedAccordion !== taxCostType) : (type === 'receiptExpenditureTaxCostTypesInUse' ? openAllExpenditureReceiptsTables : openAllIncomeReceiptsTables) ? [...this.state[`${type}OpenedAccordions`], taxCostType] : [taxCostType]})}
        label={
        <Group onClick={()=> isMobile && this.setMobileViewStates(true, this.renderReceiptsTable(taxCostType), taxCostType)} id={taxCostType} style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}} position='apart'>
          <span style={{fontWeight: '500', fontSize: isMobile ? '12px' : '14px', fontFamily: 'Inter, sans-sarif'}}>{taxCostType}</span>
          {!isMobile && (this.state[`${type}OpenedAccordions`].find((openedAccordion)=> openedAccordion === taxCostType) === undefined ? <AddIcon style={{fontSize: '14px', color: '#5655FE'}}/> : <RemoveIcon style={{fontSize: '14px', color: '#5655FE'}}/>)}
        </Group>}
        >
           {!isMobile && this.renderReceiptsTable(taxCostType, type)}
        </Accordion.Item>)}
      </Accordion>
    )
  }

  renderPrivateTenantAppendixTable=()=>{
    return(
      <TableContainer>
        <Table style={{backgroundColor: '#F7F7FA'}}>
          {this.renderTenantAppendixHeader()}
          {this.renderForPrivateTenantAppendixBody()}
        </Table>
      </TableContainer>
    )
  }

  renderCommercialTenantAppendixTable=()=>{
    return(
      <TableContainer>
        <Table style={{backgroundColor: '#F7F7FA'}}>
          {this.renderTenantAppendixHeader()}
          {this.renderForCommercialTenantAppendixBody()}
        </Table>
      </TableContainer>
    )
  }

  renderUmlagenTable=()=>{
    return(
      <TableContainer>
        <Table style={{backgroundColor: '#F7F7FA'}}>
          {this.renderUmlagenHeader()}
          {this.renderForUmlagenAppendixBody()}
        </Table>
      </TableContainer>
    )
  }

  renderIncomeTenantsAppendixRows=()=>{
    const {setOpenedAccordionsIncomeRentals, incomeTenantsAccordionState, isMobile} = this.state;
    return(
      <Accordion
      classNames={{
        control: 'tax-declaration-appendix-accordion-control',
        content: 'tax-declaration-appendix-accordion-content',
        icon: 'tax-declaration-appendix-accordion-icon',
        contentInner: 'tax-declaration-appendix-accordion-contentInner'
      }}
      state={incomeTenantsAccordionState}
      multiple={true}
      onChange={(state)=> !isMobile && this.setState({incomeTenantsAccordionState: state})}
      >
        <Accordion.Item
        onClick={()=> !isMobile && this.setState({setOpenedAccordionsIncomeRentals: setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'privateTenants') !== undefined ? setOpenedAccordionsIncomeRentals.filter((openedAccordion)=> openedAccordion !== 'privateTenants') : [...setOpenedAccordionsIncomeRentals, 'privateTenants']})}
        label={
        <Group onClick={()=> isMobile && this.setMobileViewStates(true, this.renderPrivateTenantAppendixTable(), 'Mieteinnahmen Wohnungen')} id='total-private-tenants-net-rent' style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}} position='apart'>
          <span style={{fontWeight: '500', fontSize: isMobile ? '12px' : '14px', fontFamily: 'Inter, sans-sarif'}}>Mieteinnahmen Wohnungen</span>
          {!isMobile && (setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'privateTenants') === undefined ? <AddIcon style={{fontSize: '14px', color: '#5655FE'}}/> : <RemoveIcon style={{fontSize: '14px', color: '#5655FE'}}/>)}
        </Group>}
        
        >
          {!isMobile && this.renderPrivateTenantAppendixTable()}
        </Accordion.Item>
        <Accordion.Item
        onClick={()=> !isMobile && this.setState({setOpenedAccordionsIncomeRentals: setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'commercialTenants') !== undefined ? setOpenedAccordionsIncomeRentals.filter((openedAccordion)=> openedAccordion !== 'commercialTenants') : [...setOpenedAccordionsIncomeRentals, 'commercialTenants']})}
        label={
        <Group onClick={()=> isMobile && this.setMobileViewStates(true, this.renderCommercialTenantAppendixTable(), 'Mieteinnahmen andere Räume')} id='total-commercial-tenants-net-rent' style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}} position='apart'>
          <span style={{fontWeight: '500', fontSize: isMobile ? '12px' : '14px', fontFamily: 'Inter, sans-sarif'}}>Mieteinnahmen andere Räume</span>
          {!isMobile && (setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'commercialTenants') === undefined ? <AddIcon style={{fontSize: '14px', color: '#5655FE'}}/> : <RemoveIcon style={{fontSize: '14px', color: '#5655FE'}}/>)}
        </Group>}
        >
          {!isMobile && this.renderCommercialTenantAppendixTable()}
        </Accordion.Item>

        <Accordion.Item
        onClick={()=> !isMobile && this.setState({setOpenedAccordionsIncomeRentals: setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'prePayments') !== undefined ? setOpenedAccordionsIncomeRentals.filter((openedAccordion)=> openedAccordion !== 'prePayments') : [...setOpenedAccordionsIncomeRentals, 'prePayments']})}
        label={
        <Group onClick={()=> isMobile && this.setMobileViewStates(true, this.renderUmlagenTable(), 'Umlagen')} id='total-umlagen' style={{cursor: 'pointer', backgroundColor: 'white', padding: '10px', borderRadius: '4px'}} position='apart'>
          <span style={{fontWeight: '500', fontSize: isMobile ? '12px' : '14px', fontFamily: 'Inter, sans-sarif'}}>Umlagen</span>
          {!isMobile && (setOpenedAccordionsIncomeRentals.find((openedAccordion)=> openedAccordion === 'prePayments') === undefined ? <AddIcon style={{fontSize: '14px', color: '#5655FE'}}/> : <RemoveIcon style={{fontSize: '14px', color: '#5655FE'}}/>)}
        </Group>}
        >
          {!isMobile && this.renderUmlagenTable()}
        </Accordion.Item>
      </Accordion>
    )
  }

  renderTenantAppendixHeader=()=>{
    return (
      <TableHead>
        <TableRow>
          <TableCell width={'40%'} className='tax-declaration-table-header'>Mieter</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>Kaltmiete</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>Vorauszahlungen</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>USt.</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderUmlagenHeader=()=>{
    return (
      <TableHead>
        <TableRow>
          <TableCell width={'50%'} className='tax-declaration-table-header'>Mieter</TableCell>
          <TableCell width={'50%'} className='tax-declaration-table-header'>Umlagen</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  doesDateAOverlapWithDateB=(dateAStart, dateAEnd, dateBStart, dateBEnd)=> {
    const dateAStartDayjs = dayjs(dateAStart);
    const dateAEndDayjs = dayjs(dateAEnd);
    const dateBStartDayjs = dayjs(dateBStart, 'DD.MM.YYYY');
    const dateBEndDayjs = dayjs(dateBEnd, 'DD.MM.YYYY');

    // Check if dateA overlaps with dateB
    console.log('doesDateAOverlapWithDateB: ', dateAStartDayjs.isBefore(dateBEndDayjs) && dateAEndDayjs.isAfter(dateBStartDayjs));
    return dateAStartDayjs.isBefore(dateBEndDayjs) && dateAEndDayjs.isAfter(dateBStartDayjs);
  }

  searchExpandRow=(searchValue)=>{
    const {expandAppendixRowTaxType} = this.state;
    return expandAppendixRowTaxType.find((value)=> value === searchValue) !== undefined ? true : false;
  }

  addOrRemoveToExpandRow=(addOrRemoveValue)=>{
    let {expandAppendixRowTaxType} = this.state;
    if(expandAppendixRowTaxType.find((value)=> value === addOrRemoveValue) !== undefined){
      expandAppendixRowTaxType= expandAppendixRowTaxType.filter((value)=> value !== addOrRemoveValue);
      this.setState({expandAppendixRowTaxType});
      return null;
    }else{
      expandAppendixRowTaxType.push(addOrRemoveValue);
      this.setState({expandAppendixRowTaxType});
      return null;
    }
  }

  isTenantInYear(moveInDate, moveOutDate) {
    const {selectedAnnualYear} = this.state;
    const startOfYear = dayjs(`${selectedAnnualYear}-01-01`);
    const endOfYear = dayjs(`${selectedAnnualYear}-12-31`);

    const moveIn = dayjs(moveInDate);
    const moveOut = moveOutDate ? dayjs(moveOutDate) : null;

    // Check if moveOutDate is null (tenant still lives there)
    if (!moveOut) {
        return moveIn.isBefore(endOfYear) || moveIn.isSame(endOfYear);
    }

    // Check if the tenant was in the apartment at any point during the year
    return (moveIn.isBefore(endOfYear) && moveOut.isAfter(startOfYear)) ||
           moveIn.isSame(startOfYear) ||
           moveOut.isSame(endOfYear);
}

  renderForPrivateTenantAppendixBody=()=>{
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, receiptExpenditureTaxCostTypesInUse, expandAppendixRowTaxType} = this.state;

    const selectedAnnualYearOptionData= annualYearOptions.find((option)=> option.optionData.id === selectedAnnualYear);
    const privateTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const privateTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);

    return (
      <TableBody>
        {rentalUnits.find((rentalUnit)=> rentalUnit?.tenants?.length > 0) !== undefined ?
        <>
          {/* For private tenants */}
          <>
            {rentalUnits.map((rentalUnit)=> 
              rentalUnit?.tenants?.map((tenant)=>{
              console.log('isTenantInYear: ', this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate), tenant?.fullName);
              if(tenant.contractType === 'private' && tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){
                const rentsFromSelectedYear=  tenant?.rents// && tenant?.rents.filter((rent)=> rent.endDate !== null ? this.doesDateAOverlapWithDateB(rent.startDate, rent.endDate, selectedAnnualYearOptionData?.optionData?.startDate, selectedAnnualYearOptionData?.optionData?.endDate) : rent);
                console.log('rentsFromSelectedYear: ', rentsFromSelectedYear);
                return (
                  <>
                  <TableRow style={{cursor: 'pointer'}} onClick={()=> this.props.history.push(`/user/rentIncomeTracking?selectedTenant=${encodeURIComponent(JSON.stringify([tenant?.id]))}&startDate=${selectedAnnualYear}-01-01T00:00:00.000Z&endDate=${selectedAnnualYear}-12-31T00:00:00.000Z`)}>
                    <TableCell //onClick={()=> this.setState({expandAppendixRowTaxType: expandAppendixRowTaxType === tenant?.id ? null : tenant?.id})} 
                    className='tax-declaration-table-body'>
                      {tenant?.fullName}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0), '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0), '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      -
                    </TableCell>
                  </TableRow>
                  {/* <TableRow className={`main-row ${expandAppendixRowTaxType == tenant?.id ? 'expanded' : ''}`}>
                    <TableCell onClick={()=> this.setState({expandAppendixRowTaxType: expandAppendixRowTaxType === tenant?.id ? null : tenant?.id})} colSpan={4} style={{cursor: 'pointer'}} className='tax-declaration-table-header'>
                      <Group noWrap position='apart'>
                        <span>{tenant?.fullName}</span>
                        {expandAppendixRowTaxType === tenant?.id ? <RemoveIcon style={{fontSize: '14px'}}/> : <AddIcon style={{fontSize: '14px'}}/>}
                      </Group>
                    </TableCell>
                  </TableRow>
                  {expandAppendixRowTaxType === tenant?.id && 
                  (rentsFromSelectedYear?.length > 0 ?
                  rentsFromSelectedYear.map((rent)=>
                  <TableRow className="detail-row">
                    <TableCell className='tax-declaration-table-body'>
                      {`${dayjs(rent.startDate).format('DD.MM.YYYY')} - ${rent.endDate ? dayjs(rent.endDate).format('DD.MM.YYYY') : ''}`}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rent?.netRent, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rent?.prePayments, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      -
                    </TableCell>
                  </TableRow>
                  )
                  :
                  <TableRow>
                    <TableCell className='tax-declaration-table-body' colSpan={4}>
                      <EmptyStateContainer
                      heading={'Non available'}
                      subheading={'No rents data available'}
                      showBtn={false}
                      styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
                      icon={EmptyIcon}
                      />    
                    </TableCell>
                  </TableRow>)} */}
                </>)
              }else{
                return null
              } 
              }))
            }
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-private-tenants-net-rent'}>Gesamt Wohnungen</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(privateTenantsTotalNetRent, '€', 2, '.', ',', '%v %s')}</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(privateTenantsTotalPrePayments, '€', 2, '.', ',', '%v %s')}</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}}>-</TableCell>
            </TableRow>
          </> 
        </>
      :
      <TableRow>
        <TableCell className='tax-declaration-table-body' colSpan={4}>
          <EmptyStateContainer
          heading={'Keine relevanten Belege gefunden'}
          subheading={''}
          showBtn={false}
          styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
          icon={EmptyIcon}
          />    
        </TableCell>
      </TableRow>
      }
      </TableBody>
    )
  }

  renderForCommercialTenantAppendixBody=()=>{
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, receiptExpenditureTaxCostTypesInUse, expandAppendixRowTaxType} = this.state;

    const selectedAnnualYearOptionData= annualYearOptions.find((option)=> option.optionData.id === selectedAnnualYear);
    const commercialTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const commercialTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
    let commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent / 1.19;
    commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent - commercialTenantsTotalNetRentVatAmount;
    let commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments / 1.19;
    commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount;

    return (
      <TableBody>
        {rentalUnits.find((rentalUnit)=> rentalUnit?.tenants?.length > 0) !== undefined ?
        <>
          {/* For commercial tenants */}
          <>
            {rentalUnits.map((rentalUnit)=> 
              rentalUnit?.tenants?.map((tenant)=>{
                const rentsFromSelectedYear= tenant?.rents //&& tenant?.rents.filter((rent)=> rent.endDate !== null ? this.doesDateAOverlapWithDateB(rent.startDate, rent.endDate, selectedAnnualYearOptionData?.optionData?.startDate, selectedAnnualYearOptionData?.optionData?.endDate) : rent);
                console.log('isTenantInYear: ', this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate), tenant?.fullName);
              if(tenant.contractType === 'commercial' && tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){

                const commercialTenantRowTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
                const commercialTenantRowTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
                let commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent / 1.19;
                commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount;
                let commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments / 1.19;
                commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount;

                return (
                  <>
                  <TableRow style={{cursor: 'pointer'}} onClick={()=> this.props.history.push(`/user/rentIncomeTracking?selectedTenant=${encodeURIComponent(JSON.stringify([tenant?.id]))}&startDate=${selectedAnnualYear}-01-01T00:00:00.000Z&endDate=${selectedAnnualYear}-12-31T00:00:00.000Z`)}>
                    <TableCell //onClick={()=> this.setState({expandAppendixRowTaxType: expandAppendixRowTaxType === tenant?.id ? null : tenant?.id})} 
                    className='tax-declaration-table-body'>
                      {tenant?.fullName}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(commercialTenantRowTotalNetRent ? commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount : 0, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(commercialTenantRowTotalPrePayments ? commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount : 0, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(commercialTenantRowTotalPrePaymentsVatAmount + commercialTenantRowTotalNetRentVatAmount, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                  </TableRow>
                  {/* {expandAppendixRowTaxType === tenant?.id && 
                  (rentsFromSelectedYear?.length > 0 ?
                  rentsFromSelectedYear.map((rent)=>
                  <TableRow className="detail-row">
                    <TableCell className='tax-declaration-table-body'>
                      {`${dayjs(rent.startDate).format('DD.MM.YYYY')} - ${rent.endDate ? dayjs(rent.endDate).format('DD.MM.YYYY') : ''}`}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rent?.netRent, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(rent?.prePayments, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      -
                    </TableCell>
                  </TableRow>
                  )
                  :
                  <TableRow>
                    <TableCell className='tax-declaration-table-body' colSpan={4}>
                      <EmptyStateContainer
                      heading={'Non available'}
                      subheading={'No rents data available'}
                      showBtn={false}
                      styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
                      icon={EmptyIcon}
                      />    
                    </TableCell>
                  </TableRow>)} */}
                </>)
              }else{
                return null
              } 
              }))
            }
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-commercial-tenants-net-rent'}>Gesamt andere Räume</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(commercialTenantsTotalNetRent - commercialTenantsTotalNetRentVatAmount, '€', 2, '.', ',', '%v %s')}</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount, '€', 2, '.', ',', '%v %s')}</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}}>{accounting.formatMoney(commercialTenantsTotalPrePaymentsVatAmount + commercialTenantsTotalNetRentVatAmount, '€', 2, '.', ',', '%v %s')}</TableCell>
            </TableRow>
          </>
        </>
      :
      <TableRow>
        <TableCell className='tax-declaration-table-body' colSpan={4}>
          <EmptyStateContainer
          heading={'Keine relevanten Belege gefunden'}
          subheading={''}
          showBtn={false}
          styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
          icon={EmptyIcon}
          />    
        </TableCell>
      </TableRow>
      }
      </TableBody>
    )
  }

  renderForUmlagenAppendixBody=()=>{
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, receiptExpenditureTaxCostTypesInUse, expandAppendixRowTaxType} = this.state;

    const selectedAnnualYearOptionData= annualYearOptions.find((option)=> option.optionData.id === selectedAnnualYear);
    const privateTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const privateTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);


    const commercialTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const commercialTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
    let commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent / 1.19;
    commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent - commercialTenantsTotalNetRentVatAmount;
    let commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments / 1.19;
    commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount;

    const creditTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erstattete Vorauszahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    const debitTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erhaltene Nachzahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);

    return (
      <TableBody>
        {rentalUnits.find((rentalUnit)=> rentalUnit?.tenants?.length > 0) !== undefined ?
        <>
          {/* For commercial tenants */}
          <>
            {rentalUnits.map((rentalUnit)=> 
              rentalUnit?.tenants?.map((tenant)=>{
                const rentsFromSelectedYear= tenant?.rents //&& tenant?.rents.filter((rent)=> rent.endDate !== null ? this.doesDateAOverlapWithDateB(rent.startDate, rent.endDate, selectedAnnualYearOptionData?.optionData?.startDate, selectedAnnualYearOptionData?.optionData?.endDate) : rent);
              if(tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){

                const commercialTenantRowTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
                const commercialTenantRowTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
                let commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent / 1.19;
                commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount;
                let commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments / 1.19;
                commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount;

                return (
                  <>
                  <TableRow style={{cursor: 'pointer'}} onClick={()=> this.props.history.push(`/user/rentIncomeTracking?selectedTenant=${encodeURIComponent(JSON.stringify([tenant?.id]))}&startDate=${selectedAnnualYear}-01-01T00:00:00.000Z&endDate=${selectedAnnualYear}-12-31T00:00:00.000Z`)}>
                    <TableCell //onClick={()=> this.setState({expandAppendixRowTaxType: expandAppendixRowTaxType === tenant?.id ? null : tenant?.id})} 
                    className='tax-declaration-table-body'>
                      {tenant?.fullName}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(
                        (commercialTenantRowTotalPrePayments ? commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount : 0)
                        +
                        rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0)
                        , '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                  </TableRow>
                </>)
              }else{
                return null
              } 
              }))
            }
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-commercial-tenants-net-rent'}>Erhaltene Vorauszahlungen</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney((commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount) + privateTenantsTotalPrePayments, '€', 2, '.', ',', '%v %s')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-commercial-tenants-net-rent'}>Erstattete Vorauszahlungen</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-commercial-tenants-net-rent'}>Erhaltene Nachzahlungen</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + a.costs, 0), '€', 2, '.', ',', '%v %s')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}} id={'total-commercial-tenants-net-rent'}>Gesamt</TableCell>
              <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(
                ((commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount) + privateTenantsTotalPrePayments)
                + 
                (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
                + 
                debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)), '€', 2, '.', ',', '%v %s')}
              </TableCell>
            </TableRow>
          </>
        </>
      :
      <TableRow>
        <TableCell className='tax-declaration-table-body' colSpan={2}>
          <EmptyStateContainer
          heading={'Keine relevanten Belege gefunden'}
          subheading={''}
          showBtn={false}
          styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
          icon={EmptyIcon}
          />    
        </TableCell>
      </TableRow>
      }
      </TableBody>
    )
  }

  renderAppendixHeader=()=>{
    return (
      <TableHead>
        <TableRow>
          <TableCell width={'40%'} className='tax-declaration-table-header'>Bezeichnung</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>Datum</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>Belegnummer</TableCell>
          <TableCell width={'20%'} className='tax-declaration-table-header'>Rechnungsbetrag</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  renderAppendixBody=(taxCostType, type)=>{
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, receiptExpenditureTaxCostTypesInUse, expandAppendixRowTaxType} = this.state;

    const taxCostTypeRelatedReceipts= receipts.filter((receipt)=> receipt.taxCostType === (taxCostType === 'Absetzung für Abnutzung für Gebäude' ? 'AfA Gebäude' : taxCostType === 'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter' ? 'AfA bewegliche Wirtschaftsgüter' : taxCostType === 'Sonstiges' ? 'Sonstige Kosten' : taxCostType) && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    return (
      <TableBody>
        {taxCostTypeRelatedReceipts.length > 0 ?
        //taxCostTypeRelatedReceipts.length > 0 ?
        <>
          {/* {receiptExpenditureTaxCostTypesInUse.map((taxCostType)=> {
            
            return(
              <>
                <TableRow id={taxCostType} className={`main-row ${expandAppendixRowTaxType == taxCostType ? 'expanded' : ''}`}>
                  <TableCell onClick={()=> this.setState({expandAppendixRowTaxType: expandAppendixRowTaxType === taxCostType ? null : taxCostType})} colSpan={4} style={{cursor: 'pointer'}} className='tax-declaration-table-header'>
                    <Group noWrap position='apart'>
                      <span>{taxCostType}</span>
                      {expandAppendixRowTaxType === taxCostType ? <RemoveIcon style={{fontSize: '14px'}}/> : <AddIcon style={{fontSize: '14px'}}/>}
                    </Group>
                  </TableCell>
                </TableRow>
                {taxCostTypeRelatedReceipts.length > 0 ?
                <>
                  {
                  // expandAppendixRowTaxType === taxCostType && 
                  taxCostTypeRelatedReceipts.map((receipt)=>
                  <TableRow className="detail-row">
                    <TableCell className='tax-declaration-table-body'>
                      {receipt.name}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {dayjs(receipt.receiptDate).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {receipt.receiptNumber}
                    </TableCell>
                    <TableCell className='tax-declaration-table-body'>
                      {accounting.formatMoney(receipt.costs, '€', 2, '.', ',', '%v %s')}
                    </TableCell>
                  </TableRow>
                  )}
                  {expandAppendixRowTaxType === taxCostType &&
                  taxCostTypeRelatedReceipts.length > 0 &&
                  <TableRow>
                    <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}}>{`Total ${taxCostType}`}</TableCell>
                    <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}} colSpan={3}>{accounting.formatMoney(taxCostTypeRelatedReceipts.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0), '€', 2, '.', ',', '%v %s')}</TableCell>
                  </TableRow>}
                </>
                :
                // expandAppendixRowTaxType === taxCostType && 
                <TableRow>
                  <TableCell className='tax-declaration-table-body' colSpan={4}>
                    <EmptyStateContainer
                    heading={'Non available'}
                    subheading={'No receipts data available'}
                    showBtn={false}
                    styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
                    icon={EmptyIcon}
                    />    
                  </TableCell>
                </TableRow>}
              </>
            )
          
          })} */}
          
          {taxCostTypeRelatedReceipts.map((receipt)=>
          <TableRow style={{cursor: 'pointer'}} onClick={()=> this.getRecord(receipt.id, true)}>
            <TableCell className='tax-declaration-table-body'>
              {receipt.name}
            </TableCell>
            <TableCell className='tax-declaration-table-body'>
              {dayjs(receipt.receiptDate).format('DD.MM.YYYY')}
            </TableCell>
            <TableCell className='tax-declaration-table-body'>
              {receipt.receiptNumber ? receipt.receiptNumber : '-'}
            </TableCell>
            <TableCell className='tax-declaration-table-body'>
              {accounting.formatMoney(type === 'receiptExpenditureTaxCostTypesInUse' ? (receipt.costs * -1) : receipt.costs, '€', 2, '.', ',', '%v %s')}
            </TableCell>
          </TableRow>
          )}
          <TableRow>
            <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}}colSpan={2}/>
            <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold'}}>Gesamt</TableCell>
            <TableCell className='tax-declaration-table-body' style={{fontWeight: 'bold', textAlign: 'left'}}>{accounting.formatMoney(taxCostTypeRelatedReceipts.reduce((partialSum, a) => (partialSum ? partialSum : null) + (type === 'receiptExpenditureTaxCostTypesInUse' ? (a.costs * -1) : a.costs), 0), '€', 2, '.', ',', '%v %s')}</TableCell>
          </TableRow>
        </> 
      :
      <TableRow>
        <TableCell className='tax-declaration-table-body' colSpan={4}>
          <EmptyStateContainer
          heading={'Keine relevanten Belege gefunden'}
          subheading={''}
          showBtn={false}
          styleMainContainer={{marginTop: 0, padding: '20px 0 10px'}}
          icon={EmptyIcon}
          />    
        </TableCell>
      </TableRow>
      }
      </TableBody>
    )
  }

  downloadExcelTable = () => {
    const {accountData, propertySelected} = this.props;
    const {rentalUnits, receipts, selectedBillingPeriod, utilityBillings, selectedAnnualYear, annualYearOptions, loaderBP, loaderReceipts, loaderRentalUnit, loaderUB} = this.state;
    console.log('propertySelected: ', propertySelected);
    console.log('rentalUnits: ', rentalUnits);
    console.log('receipts: ', receipts);
    
    const selectedAnnualYearOptionData= annualYearOptions.find((option)=> option.optionData.id === selectedAnnualYear);
    console.log('selectedAnnualYearOptionData: ', selectedAnnualYearOptionData);
    const totalNetRentForPrivateTenants_9= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'private' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const totalNetRentForCommercialTenants_10= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);

    const advancePayments_13= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? (c.receivedAdvancePayment / 1.19) : c.receivedAdvancePayment), 0) : null), 0) : null), 0);
    const creditTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erstattete Vorauszahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    const debitTaxCostTypeReceipts_13= receipts.filter((receipt)=> receipt.taxCostType === 'Erhaltene Nachzahlungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
    const retainedDepositTaxCostTypeReceipts_15= receipts.filter((receipt)=> receipt.taxCostType === 'Vereinnahmte Kautionen / Mieten aus Vorjahren' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    const totalGrossRentForCommercialTenants_17= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + (b.contractType === 'commercial' ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent  + c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
    const vatTaxRefundByTaxOffice_18= receipts.filter((receipt)=> receipt.taxCostType === 'Vom Finanzamt erstattete Umsatzsteuer' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    const publicGrants_20 = receipts.filter((receipt)=> receipt.taxCostType === 'Öffentliche Zuschüsse' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear)//.map((receipt)=> receipt.costs >= 0 ? ({...receipt, costs: -(receipt.costs * -1)}) : ({...receipt, costs: (receipt.costs * -1)}));
    
    const valueFor_33= receipts.filter((receipt)=> receipt.taxCostType === 'AfA Gebäude' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_36= receipts.filter((receipt)=> receipt.taxCostType === 'AfA bewegliche Wirtschaftsgüter' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_37= receipts.filter((receipt)=> receipt.taxCostType === 'Schuldzinsen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_38= receipts.filter((receipt)=> receipt.taxCostType === 'Geldbeschaffungskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_39= receipts.filter((receipt)=> receipt.taxCostType === 'Renten, dauernde Lasten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_40= receipts.filter((receipt)=> receipt.taxCostType === 'Erhaltungsaufwendungen' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_47= receipts.filter((receipt)=> receipt.taxCostType === 'Laufende Betriebskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_48= receipts.filter((receipt)=> receipt.taxCostType === 'Verwaltungskosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_49= receipts.filter((receipt)=> receipt.taxCostType === 'An Finanzamt gezahlte Umsatzsteuer' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));
    const valueFor_50= receipts.filter((receipt)=> receipt.taxCostType === 'Sonstige Kosten' && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear).map((receipt)=> ({...receipt, costs: (receipt.costs * -1)}));

    const valueFor_51_22= valueFor_33.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_36.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_37.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_38.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_39.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_40.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_47.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_48.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_49.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + 
    valueFor_50.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0);
    let firstRow = `Datenexport für Anlage V (${selectedAnnualYear})`;


    const privateTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const privateTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);

    const commercialTenantsTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
    const commercialTenantsTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial') ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
    let commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent / 1.19;
    commercialTenantsTotalNetRentVatAmount= commercialTenantsTotalNetRent - commercialTenantsTotalNetRentVatAmount;
    let commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments / 1.19;
    commercialTenantsTotalPrePaymentsVatAmount= commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount;
    

    var aoa = [
      [firstRow, "", ""],
      ["", "", ""],
      ["Zeile", "Schlüssel", "Wert"],
      ["1", "Name / Gemeinschaft", accountData.addressCompany],
      ["Lage des Grundstücks / der Eigentumswohnung", "", ""],
      ["4", "Straße, Hausnummer", propertySelected.propertyAddress],
      ["5", "Postleitzahl, Ort", `${propertySelected.propertyZipCode} ${propertySelected.propertyCity}`],
      ["Einkünfte", "", ""],
      ["8", "Gesamtwohnfläche (m2)", rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.space ? a.space : null), 0)],
      ["9", "Mieteinnahmen für Wohnungen (ohne Umlagen)", totalNetRentForPrivateTenants_9],
      ["10", "Mieteinnahmen für andere Räume (ohne Umlagen)", totalNetRentForCommercialTenants_10],
      ["13", "Umlagen",
        advancePayments_13
        +
        (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
        +
        debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0))
      ],
      ["15", "Mieten aus Vorjahren, vereinnahmte Kautionen", retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["17", "Vereinnahmte Umsatzsteuer", totalGrossRentForCommercialTenants_17 -(totalGrossRentForCommercialTenants_17 / 1.19)],
      ["18", "Vom Finanzamt erstattete Umsatzsteuer", vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["20", "Öffentliche Zuschüsse", publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["21", "Summe der Einnahmen",
        totalNetRentForPrivateTenants_9 + 
        totalNetRentForCommercialTenants_10 + 
        advancePayments_13
        -
        (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
        +
        debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)) 
        +
        retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
        (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
        vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
        publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["22", "Summe der Werbungskosten", valueFor_51_22],
      ["23", "Überschuss", (
        totalNetRentForPrivateTenants_9 + 
        totalNetRentForCommercialTenants_10 + 
        advancePayments_13
        -
        (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
        +
        debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)) 
        +
        retainedDepositTaxCostTypeReceipts_15.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
        (totalGrossRentForCommercialTenants_17 - (totalGrossRentForCommercialTenants_17 / 1.19)) +
        vatTaxRefundByTaxOffice_18.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) +
        publicGrants_20.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)
      ) -
      valueFor_51_22],
      ["Werbungskosten", "", ""],
      ["33", "Absetzung für Abnutzung für Gebäude", valueFor_33.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["36", "Absetzung für Abnutzung für bewegliche Wirtschaftsgüter", valueFor_36.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["37", "Schuldzinsen", valueFor_37.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["38", "Geldbeschaffungskosten", valueFor_38.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["39", "Renten, dauernde Lasten", valueFor_39.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["40", "Erhaltungsaufwendungen", valueFor_40.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["47", "Laufende Betriebskosten", valueFor_47.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["48", "Verwaltungskosten", valueFor_48.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["49", "An Finanzamt gezahlte Umsatzsteuer", valueFor_49.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["50", "Sonstiges", valueFor_50.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)],
      ["51", "Summe der Werbungskosten", valueFor_51_22]
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["Anhang", "", ""],
      ["", "", ""],
      //["Bezeichnung", "Datum", "Belegnummer", "Rechnungsbetrag"],
    ];
    
    //Werbungskosten (detailierte Aufstellungen) - Section
    aoa.push(["Werbungskosten (detailierte Aufstellungen)"]);
    this.state.receiptExpenditureTaxCostTypesInUse.map((taxCostType)=>{
      const taxCostTypeRelatedReceipts= receipts.filter((receipt)=> receipt.taxCostType === (taxCostType === 'Absetzung für Abnutzung für Gebäude' ? 'AfA Gebäude' : taxCostType === 'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter' ? 'AfA bewegliche Wirtschaftsgüter' : taxCostType) && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
      if(taxCostTypeRelatedReceipts.length > 0){
        aoa.push([taxCostType], ["Bezeichnung", "Datum", "Belegnummer", "Rechnungsbetrag"]);
      }
      {taxCostTypeRelatedReceipts.map((receipt)=> aoa.push([receipt.name, dayjs(receipt.receiptDate).format('DD.MM.YYYY'), receipt.receiptNumber ? receipt.receiptNumber : '-', receipt.costs]))}
      taxCostTypeRelatedReceipts.length > 0 && aoa.push(["", "", "Gesamt", taxCostTypeRelatedReceipts.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)]);
    });

    //Mieteinnahmen und Vorauszahlungen (detailierte Aufstellungen) - Section
    aoa.push(
      ["", "", "", ""], 
      ["", "", "", ""], 
      ["Mieteinnahmen und Vorauszahlungen (detailierte Aufstellungen)"], 
      ["", "", "", ""], 
      ["Mieteinnahmen Wohnungen"],
      ["Mieter", "Kaltmiete", "Vorauszahlungen", "USt."],
    );

    rentalUnits.map((rentalUnit)=> 
      rentalUnit?.tenants?.map((tenant)=>{
        if(tenant.contractType === 'private' && tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){
          const rentsFromSelectedYear=  tenant?.rents //&& tenant?.rents.filter((rent)=> rent.endDate !== null ? this.doesDateAOverlapWithDateB(rent.startDate, rent.endDate, selectedAnnualYearOptionData?.optionData?.startDate, selectedAnnualYearOptionData?.optionData?.endDate) : rent);

          aoa.push([
            tenant?.fullName, 
            rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0),
            rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0),
            "-"
          ]);
        }
      }
    ));
    aoa.push(["Gesamt Wohnungen", privateTenantsTotalNetRent, privateTenantsTotalPrePayments, "-"]);

    aoa.push(
      ["", "", "", ""], 
      ["Mieteinnahmen andere Räume"], 
      ["Mieter", "Kaltmiete", "Vorauszahlungen", "USt."]
    );
    rentalUnits.map((rentalUnit)=> 
      rentalUnit?.tenants?.map((tenant)=>{
        if(tenant.contractType === 'commercial' && tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){
          const commercialTenantRowTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
          const commercialTenantRowTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
          let commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent / 1.19;
          commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount;
          let commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments / 1.19;
          commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount;

          aoa.push([
            tenant?.fullName, 
            commercialTenantRowTotalNetRent ? commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount : 0,
            commercialTenantRowTotalPrePayments ? commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount : 0,
            commercialTenantRowTotalPrePaymentsVatAmount + commercialTenantRowTotalNetRentVatAmount
          ]);
        }
      }
    ));
    aoa.push(["Gesamt andere Räume", commercialTenantsTotalNetRent - commercialTenantsTotalNetRentVatAmount, commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount, commercialTenantsTotalPrePaymentsVatAmount + commercialTenantsTotalNetRentVatAmount]);


    aoa.push(
      ["", "", "", ""], 
      ["Umlagen"], 
      ["Mieter", "Umlagen"]
    );
    rentalUnits.map((rentalUnit)=> 
      rentalUnit?.tenants?.map((tenant)=>{
        if(tenant.vacant === false && this.isTenantInYear(tenant.moveInDate, tenant.moveOutDate)){
          const commercialTenantRowTotalNetRent= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedNetRent), 0) : null : null), 0) : null), 0);
          const commercialTenantRowTotalPrePayments= rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'commercial' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0);
          let commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent / 1.19;
          commercialTenantRowTotalNetRentVatAmount= commercialTenantRowTotalNetRent - commercialTenantRowTotalNetRentVatAmount;
          let commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments / 1.19;
          commercialTenantRowTotalPrePaymentsVatAmount= commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount;
          aoa.push([
            tenant?.fullName, 
            (commercialTenantRowTotalPrePayments ? commercialTenantRowTotalPrePayments - commercialTenantRowTotalPrePaymentsVatAmount : 0)
            +
            rentalUnits.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a?.tenants?.length > 0 ? a?.tenants?.reduce((partialSum, b) => (partialSum ? partialSum : null) + ((b.contractType === 'private' && b.id === tenant.id) ? b?.rents?.length > 0 ? b?.rents?.reduce((partialSum, c)=> (partialSum ? partialSum : null) + (c.receivedAdvancePayment), 0) : null : null), 0) : null), 0)
          ]);
        }
      }
    ));
    aoa.push(["Erhaltene Vorauszahlungen", (commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount) + privateTenantsTotalPrePayments]);
    aoa.push(["Erstattete Vorauszahlungen", creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)]);
    aoa.push(["Erhaltene Nachzahlungen", debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + a.costs, 0)]);
    aoa.push(["Gesamt", ((commercialTenantsTotalPrePayments - commercialTenantsTotalPrePaymentsVatAmount) + privateTenantsTotalPrePayments) + (creditTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0) + debitTaxCostTypeReceipts_13.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0))]);


    //Andere Einnahmen (detaillierte Aufstellung) - Section 
    aoa.push(["", "", ""], ["", "", ""], ["Andere Einnahmen (detaillierte Aufstellung)"]);
    this.state.receiptIncomeTaxCostTypesInUse.map((taxCostType)=>{
      const taxCostTypeRelatedReceipts= receipts.filter((receipt)=> receipt.taxCostType === (taxCostType === 'Absetzung für Abnutzung für Gebäude' ? 'AfA Gebäude' : taxCostType === 'Absetzung für Abnutzung für bewegliche Wirtschaftsgüter' ? 'AfA bewegliche Wirtschaftsgüter' : taxCostType) && dayjs(receipt.receiptDate).get('year').toString() === selectedAnnualYear);
      taxCostTypeRelatedReceipts.length > 0 && aoa.push([taxCostType], ["Bezeichnung", "Datum", "Belegnummer", "Rechnungsbetrag"]);
      {taxCostTypeRelatedReceipts.map((receipt)=> aoa.push([receipt.name, dayjs(receipt.receiptDate).format('DD.MM.YYYY'), receipt.receiptNumber ? receipt.receiptNumber : '-', receipt.costs]))}
      taxCostTypeRelatedReceipts.length > 0 && aoa.push(["", "", "Gesamt", taxCostTypeRelatedReceipts.reduce((partialSum, a) => (partialSum ? partialSum : null) + (a.costs), 0)]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(aoa);

    let columns = ["A", "B","C", "D", "E"];
    columns.forEach((column)=> {
      var colNum = XLSX.utils.decode_col(column); //decode_col converts Excel col name to an integer for col #
      var fmt = '0.00'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format
  
      /* get worksheet range */
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for(var i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var ref = XLSX.utils.encode_cell({r:i, c:colNum});
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if(!worksheet[ref]) continue;
        /* `.t == "n"` for number cells */
        if(worksheet[ref].t != 'n') continue;
        /* assign the `.z` number format */
        worksheet[ref].z = fmt;
      }
    });

    var wscols = [
      {wch:20},
      {wch:40},
      {wch:15},
      {wch:15}
    ];
    worksheet['!cols'] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, firstRow);
    XLSX.writeFile(workbook, `${firstRow}.xlsx`);
  }


  getRecord = (id, openShow=true) => {
    console.log('getRecord: id:', id);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.api_base_url + config.receipts + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_record',
      {id, openShow},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        'Content-Type': 'text/xml',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // setCurrentRecord(record)
  };

  _handle_get_record = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }
    console.log('_handle_get_record: response:', response);
    let {receipts} = this.state;

    receipts = receipts.map((receipt)=> {
      if(receipt.id === response.result.id){
        receipt= response.result;
      }
      return receipt;
    });

    this.setState({receipts}, ()=> response.other.openShow && this.openShow(response.other.id, response.other.openShow, response.result));
    
  }

  openShow = (id, openShow, result) => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      {id, openShow, result},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  
  _handle_get_show_file = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }
    console.log('_handle_get_show_file: ', response);
    try {
      if(response && response.result){
        if(response && response.result.includes('pdf')){
          const other= response.other;
          fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other.id +'/download',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
              'Authorization': Autoload.Utils.getAuthToken()
            }
          })
                .then(response => {
                    response.blob()
                    .then(blob => {
                      console.log('blob: ', blob, other)
                      
                      this.setState({
                        selectedReceiptRecord: { ...other.result, file_name: blob.size > 0 ? blob : '' },
                        openReceiptDetailModal: true,
                      });
                      })
                      // var loader = document.getElementById('hello-housing-loader');
                      // loader.style.display = 'none';
                  })
        }
        else {
          this.setState({
            selectedReceiptRecord: { ...response.other.result, file_name: response.result != null ? response.result : '' },
            openReceiptDetailModal: response.other.openShow && true,
          });
          // var loader = document.getElementById('hello-housing-loader');
          // loader.style.display = 'none';
        }
      }else{
        this.setState({
          openReceiptDetailModal: response.other.openShow && true,
          selectedReceiptRecord: response.other.result
        });
      }
    } catch (error) {
      console.error('_handle_get_show_file: ', error);
    }
    
    
  };

  handleEditClick = () => {
    this.setState({openReceiptDetailModal: false});
    setTimeout(() => this.toggleEditReceiptModal(), 500);
  };

  toggleEditReceiptModal = () => {  
    this.setState({ editReceiptOpen: !this.state.editReceiptOpen });
  };

  renderExport=()=>{
    const {isMobile, exportAnlageVOpen, rentalUnits, receipts} = this.state;
    const {currentSubscription} = this.props;
    return(
      <Group spacing={5} style={{height: '36px'}}>
        {!isMobile && <span style={{color: '#454576', marginTop: '-2px'}}>Exportieren: </span>}
        <Popover
        disabled={(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER')}
        opened={exportAnlageVOpen}
        width={'auto'}
        position="bottom"
        withArrow
        onClose={() => this.setState({exportAnlageVOpen: false})}
        target={
        <Tooltip title={'Mieteraufstellung exportieren'}>
          <IconButton 
            aria-label="filter list"
            disabled={rentalUnits.length === 0}
            onClick={()=> this.setState({exportAnlageVOpen: true})} 
            style={{
              background: 'white',//'#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: rentalUnits.length === 0 ? 0.5 : 1
              }}>
              <DownloadIcon size='14px' width='11px' height='11px' fill={'#5655FE'}/>
          </IconButton>
        </Tooltip>
        }
        styles={{
          inner: { padding: '5px'},
        }}>
          <Stack spacing={0}>
            <Group 
            style={{cursor: 'pointer'}}
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> rentalUnits.length > 0 && this.downloadExcelTable()}
            >
              <img src={XlsxIcon} width={'20px'} />
              <span>Excel exportieren</span>
            </Group>
            <Group 
            style={{cursor: 'pointer'}} 
            sx={() => ({
              padding: '10px',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#E3E3FC',
              }
            })}
            onClick={()=> window.open('/taxDeclarationPdfExport', '_blank')}
            >
              {/* <CreateNewFolder
              style={{
                color: '#5655FE',
                fontSize: '20px'
              }}/> */}
              <img src={PdfIcon} width={'20px'} />
              <span>PDF exportieren</span>
            </Group>
            <Tooltip title={receipts.find((receipt)=> receipt.fileName && receipt.fileName.length > 0) !== undefined ? "Belegdokumente herunterladen" : "Für die aktuell ausgewählten Belege sind keine Anhänge verfügbar."} >
              <Group 
              style={{cursor: 'pointer'}} 
              sx={() => ({
                padding: '10px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#E3E3FC',
                }
              })}
              onClick={()=> receipts.find((receipt)=> receipt.fileName && receipt.fileName.length > 0) !== undefined && this.setState({openBulkReceiptDownloadModal: true})}
              >
                <DownloadIcon size='20px' fill={'#5655FE'}/>
                <span>Belegdokumente herunterladen</span>
              </Group>
            </Tooltip>
          </Stack>
        </Popover>
      </Group>
    )
  }

  setMobileViewStates=(openDisplayModalForMobileView, contentForMobileModal, headingForMobileModal)=>{
    this.setState({openDisplayModalForMobileView, contentForMobileModal, headingForMobileModal});
  }

  render() {
    const {dropdownIcon, startDate, endDate, billingPeriods, selectedBillingPeriod, annualYearOptions, selectedAnnualYear, rentalUnits, expenditureReceiptsAccordionState, incomeReceiptsAccordionState, loaderReceipts, loaderRentalUnit, exportAnlageVOpen, openAllExpenditureReceiptsTables, openAllIncomeReceiptsTables, receiptExpenditureTaxCostTypesInUse, receiptIncomeTaxCostTypesInUse, openAllTenantsRentalTables, openReceiptDetailModal, selectedReceiptRecord, openReceiptImageModal, editReceiptOpen, receipts, selectedReceipts, openBulkReceiptDownloadModal, isMobile, openDisplayModalForMobileView, headingForMobileModal, contentForMobileModal} = this.state;
    const {currentSubscription, accountData} = this.props;
    console.log('billingPeriods: ', billingPeriods);
    console.log('startDate: ', startDate);

    console.log('annualYearOptions: ', annualYearOptions, selectedAnnualYear);

    console.log('rentalUnits: ', rentalUnits);

    console.log('receiptIncomeTaxCostTypesInUse, receiptExpenditureTaxCostTypesInUse: ', expenditureReceiptsAccordionState, incomeReceiptsAccordionState);
    console.log('state: ', this.state);

    return (
        <Stack id='tax-declaration-document' spacing={0}>
          <Group style={{marginTop: isMobile ? '0px' : '20px'}}>
          <div style={{display: 'flex'}}>
            {breadcrumbsContent.map((item)=> item.area === '/accounting/anlage-v' && 
            item.breadcrumbs.map((breadcrumb)=>
            breadcrumb.type === 'tour' ? breadcrumb.view === ('desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
              <img src={TourIcon} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px',}}>{breadcrumb.label}</span>
            </div>
            :
            breadcrumb.view === (isMobile ? 'mobile' : 'desktop') &&
            <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
              <img src={HelpLogo} />
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </div>
            )
            )}
          </div>
          </Group>
          <Group noWrap style={{marginTop: '10px', width: '100%'}} position='apart' align={'flex-end'}>
            <Group style={{width: isMobile && '100%'}}>
              <Select
              placeholder="Wählen"
              label={'Steuerjahr'}
              onChange={(value)=> this.setState({selectedAnnualYear: value}, ()=> {
                this.props.setSelectedTaxYearForTaxDeclaration(value);
                this.getRentalPayments();
                this.filterRelevantReceipts();
              })}
              data={annualYearOptions}
              value={selectedAnnualYear}
              styles={{
                  input: {
                    minWidth: '210px',
                    maxWidth: '100%'
                  },
                  dropdown: {
                    display: 'block',
                    minWidth: '210px'
                  },
                  item: {
                    color: '#0e0e1d',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontFamily: 'sans-serif',
                    whiteSpace: 'nowrap'
                  },
                  label: {
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#727293',
                    padding: 0
                  },
                  wrapper: {
                    width: isMobile && '100%'
                  },
                  root: {
                    width: isMobile && '100%'
                  }
              }}
              className={'multi-select-messages-tenants'}
              classNames={{
                  input: 'tenant-transaction-selection-input'
              }}
              rightSection={<img src={dropdownIcon}/>}
              onDropdownOpen={()=> this.setState({dropdownIcon: Up})}
              onDropdownClose={()=> this.setState({dropdownIcon: Down})}
              disabled={(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER')}
              />
            </Group>
            {this.renderExport()}
          </Group>
          <div className='divider'/>
          <Box sx={{ height: '100%', position: 'relative'}}>
            {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER') &&
            <Stack align={'center'} style={{ zIndex: 50, position: 'absolute', left: 0, right: 0, marginRight: 'auto', marginLeft: 'auto', marginTop: '200px'}}>
              <Stack spacing={0}>
                <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Alle Daten für Ihre Steuererklärung auf Knopfdruck anzeigen.</span>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing Premium.</span>
              </Stack>
              <Group>
                <div>
                  <ColorButton
                  text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                  className='purple-btn'
                  onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                  />
                </div>
              </Group>
              <Group style={{width: '460px'}}>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Daten für die Steuererklärung (Anlage V) abrufen und exportieren - mit PDF- und Excel-Export inkl. aller Anlagen.</span>
              </Group>
              {/* <Group>
                <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', '6776182')}>Zählerdatenverarbeitung kennenlernen</span>
              </Group> */}
            </Stack>}
            {(currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER') && 
            <Overlay opacity={0.5} blur={4} zIndex={5} color={'#F7F7FA'}/>}
            <Stack>
              <Stack 
              style={{
                width: '100%',
                height: '100%',
                background: 'white',
                boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
                borderRadius: '4px'
              }}>
                <Group
                  className='tooltips meter-data-mappings'
                  style={{
                      padding: '12.1px 24px',
                      borderBottom: '1px solid #dadae6',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      background: 'white'
                  }}>
                      <h5 
                      style={{
                          fontWeight: '500',
                          fontSize: isMobile ? '14px' : '16px',
                          lineHeight: '24px',
                          color: '#0e0e1d',
                          marginBottom: '0px'
                          }}>Datenexport für Anlage V {selectedAnnualYear && `Steuerjahr ${selectedAnnualYear}`}</h5>
                  </Group>
                  <Stack style={{padding: '12.1px 24px 30px'}}>
                          <TableContainer>
                            <Table style={{backgroundColor: '#F7F7FA'}}>
                              {!isMobile && this.renderTableHeader()}
                              {this.renderTableBody()}
                            </Table>
                          </TableContainer>
                  </Stack>
                
              </Stack>
              <Stack 
              style={{
                width: '100%',
                height: '100%',
                background: 'white',
                boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
                borderRadius: '4px'
              }}>
                <Group
                  className='tooltips meter-data-mappings'
                  style={{
                      padding: '12.1px 24px',
                      borderBottom: '1px solid #dadae6',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      background: 'white'
                  }}>
                      <h5 
                      style={{
                          fontWeight: '500',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#0e0e1d',
                          marginBottom: '0px'
                          }}>Anhang</h5>
                  </Group>
                  <Stack style={{padding: '12.1px 24px 30px'}}>
                    <Group position='apart' style={{paddingRight: '9px'}} noWrap>
                      <span className={isMobile ? 'tax-declaration-sub-headers-mobile' : 'tax-declaration-sub-headers'}>Werbungskosten {!isMobile && '(detailierte Aufstellungen)'}</span>
                      {!isMobile &&
                      <span
                        onClick={()=> {
                            let newExpenditureReceiptsAccordionState = this.state.expenditureReceiptsAccordionState;
                            Object.keys(newExpenditureReceiptsAccordionState).forEach(key => {
                              newExpenditureReceiptsAccordionState[key] = openAllExpenditureReceiptsTables ? false : true;
                            });
                            
                            this.setState({
                              openAllExpenditureReceiptsTables: openAllExpenditureReceiptsTables ? false : true, 
                              expenditureReceiptsAccordionState: newExpenditureReceiptsAccordionState,
                              receiptExpenditureTaxCostTypesInUseOpenedAccordions: openAllExpenditureReceiptsTables ? [] : receiptExpenditureTaxCostTypesInUse
                            });
                          }
                        }
                        style={{fontSize: isMobile ? '12px' : '14px', fontWeight: '400', color: '#727293', cursor: 'pointer', whiteSpace: 'nowrap'}}
                        >
                          {openAllExpenditureReceiptsTables ? 'alle verbergen' : 'alle anzeigen'}
                      </span>}
                    </Group>
                    {loaderReceipts ?
                    <Group noWrap position='center' style={{height: '380px'}}>
                      <Loader size={'xs'}/>
                    </Group>
                    :
                    this.renderExpenditureReceiptsAppendixRows('receiptExpenditureTaxCostTypesInUse')}

                    <Group position='apart' style={{paddingRight: '9px'}}>
                      <span className={isMobile ? 'tax-declaration-sub-headers-mobile' : 'tax-declaration-sub-headers'}>Mieteinnahmen und Vorauszahlungen {!isMobile && '(detailierte Aufstellungen)'}</span>
                      {!isMobile &&
                      <span
                        onClick={()=> {
                            let newIncomeTenantsAccordionState = this.state.incomeTenantsAccordionState;
                            Object.keys(newIncomeTenantsAccordionState).forEach(key => {
                              newIncomeTenantsAccordionState[key] = openAllTenantsRentalTables ? false : true;
                            });
                            
                            this.setState({
                              openAllTenantsRentalTables: openAllTenantsRentalTables ? false : true, 
                              incomeTenantsAccordionState: newIncomeTenantsAccordionState,
                              setOpenedAccordionsIncomeRentals: openAllTenantsRentalTables ? [] : ['privateTenants', 'commercialTenants', 'prePayments']
                            });
                          }
                        }
                        style={{fontSize: '14px', fontWeight: '400', color: '#727293', cursor: 'pointer'}}
                        >
                          {openAllTenantsRentalTables ? 'alle verbergen' : 'alle anzeigen'}
                      </span>}
                    </Group>
                    {loaderRentalUnit ?
                    <Group noWrap position='center' style={{height: '76px'}}>
                      <Loader size={'xs'}/>
                    </Group>
                    :
                    this.renderIncomeTenantsAppendixRows()}

                    <Group position='apart' style={{paddingRight: '9px'}}>
                      <span className={isMobile ? 'tax-declaration-sub-headers-mobile' : 'tax-declaration-sub-headers'}>Andere Einnahmen {!isMobile && '(detailierte Aufstellungen)'}</span>
                      {!isMobile &&
                      <span
                        onClick={()=> {
                            let newIncomeReceiptsAccordionState = this.state.incomeReceiptsAccordionState;
                            Object.keys(newIncomeReceiptsAccordionState).forEach(key => {
                              newIncomeReceiptsAccordionState[key] = openAllIncomeReceiptsTables ? false : true;
                            });
                            
                            this.setState({
                              openAllIncomeReceiptsTables: openAllIncomeReceiptsTables ? false : true, 
                              incomeReceiptsAccordionState: newIncomeReceiptsAccordionState,
                              receiptIncomeTaxCostTypesInUseOpenedAccordions: openAllIncomeReceiptsTables ? [] : receiptIncomeTaxCostTypesInUse
                            });
                          }
                        }
                        style={{fontSize: '14px', fontWeight: '400', color: '#727293', cursor: 'pointer'}}
                        >
                          {openAllIncomeReceiptsTables ? 'alle verbergen' : 'alle anzeigen'}
                      </span>}
                    </Group>
                    {loaderReceipts ?
                    <Group noWrap position='center' style={{height: '190px'}}>
                      <Loader size={'xs'}/>
                    </Group>
                    :
                    this.renderExpenditureReceiptsAppendixRows('receiptIncomeTaxCostTypesInUse')}
                  </Stack>
              </Stack>
            </Stack>
            {openReceiptDetailModal &&
            <ShowModal
            onEditClick={this.handleEditClick}
            onExpansionClick={()=> this.setState({openReceiptImageModal: true})}
            record={selectedReceiptRecord}
            className="show-receipt-modal"
            open={openReceiptDetailModal}
            closeShow={()=> this.setState({openReceiptDetailModal: false})}
            />}
            {openReceiptImageModal &&
            <ImageModal
            img={selectedReceiptRecord && selectedReceiptRecord.file_name !== '' ? selectedReceiptRecord.file_name : Receipt}
            className="expandImageModal"
            open={openReceiptImageModal}
            toggle={()=> this.setState({openReceiptImageModal: false})}
            />}

            {editReceiptOpen && 
            <ReceiptDetailModal
              history={this.props.history}
              record={selectedReceiptRecord}
              parent={this}
              className="add-edit-receipt-modal"
              edit={true}
              open={editReceiptOpen}
              costAllocation={true}
              toggle={() => this.setState({editReceiptOpen: false, selectedReceiptRecord: null})}
              refreshData={()=> this.getRecord(selectedReceiptRecord.id, false)}
              noParentAllListAvailable={true}
            />}

            {openBulkReceiptDownloadModal &&
            <BulkDownloadForReceiptsModal
            open={openBulkReceiptDownloadModal}
            toggle={()=> this.setState({openBulkReceiptDownloadModal: false})}
            selectedReceipts={receipts.filter((receipt)=> receipt.fileName && receipt.fileName.length > 0).map((receipt)=> receipt.id)}
            receiptsData={receipts}
            />}

            {openDisplayModalForMobileView &&
            <DisplayModalForMobileView
            open={openDisplayModalForMobileView}
            toggle={()=> this.setMobileViewStates(false, null, null)}
            heading={headingForMobileModal}
            content={contentForMobileModal}
            className={'display-modal-for-mobile-view'}
            />}
          </Box>
        </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    selectedTaxYearForTaxDeclaration: state.generalReducer.selectedTaxYearForTaxDeclaration
  };
};

const actions = {
  changeCurrentProperty,
  setSelectedTaxYearForTaxDeclaration
}

export default connect(mapStateToProps, actions)(TaxDeclarationScreen);
